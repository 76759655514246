import React, {Fragment, useCallback, useEffect, useState} from 'react'
import {Box, CircularProgress} from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { fetchSuggestion } from '../../repositories/suggestions'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import {useTranslation} from 'react-i18next'
import Container from '@material-ui/core/Container'
import AutomateBookingRailTrip from '../../components/automateBooking/AutomateBookingRailTrip'
import AutomateBookingHotelSearch from '../../components/automateBooking/AutomateBookingHotelSearch'
import SelectedTripsData from '../../components/automateBooking/SelectedTripsData'
import moment from 'moment/moment'
import TransferModal from '../../containers/modals/transferModal'
import LanguageChooser from '../../components/reusable/languageChooser'
import SelectFlightFares from "../../components/automateBooking/flightFare/SelectFlightFares"
import FlightIcon from '@material-ui/icons/FlightTakeoff'
import RailIcon from '@material-ui/icons/DirectionsRailway'
import TransferIcon from '@material-ui/icons/LocalTaxi'
import _ from 'lodash'
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F9F9F9'
  },

  header: {
    display: 'flex',
    position: 'sticky',
    padding: '0 20px',
    top: 0,
    zIndex: '2',
    backgroundColor: 'white',
    height: '65px',
    width: '100%',
    borderBottom: 'solid 1px #ededed',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: "0px 0px 11px -2px rgba(0,0,0,0.50)"
  },

  headerSideBlock: {
    display: 'inline-flex',
    alignItems: 'center',
    width: '60px',
    justifyContent: 'flex-start',

    '&.right': {
      justifyContent: 'flex-end',
    }
  },

  headerCenterBlock: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    width: '100%'
  },

  backButtonOuter: {
    display: 'inline-flex',
    cursor: 'pointer'
  },

  headerText: {
    fontSize: '20px',
    fontWeight: theme.typography.fontWeightBold,
    color: '#333',
    marginLeft: '15px',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
      marginLeft: '10px',
    },
  },

  headerIcon: {
    height: '25px',
    width: 'auto',
    color: '#0dc5b8',
    marginRight: '7px',

    [theme.breakpoints.down('sm')]: {
      marginRight: '4px',
      height: '20px'
    },
  },

  logoImage: {
    height: '52px',
    width: 'auto',

    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },

  contentRoot: {
    display: 'flex',
    flex: 1,
    padding: '24px 10px 25px 10px',
    flexDirection: 'column',

    alignItems: 'center',
    width:'100%',

    '&.editing-hotel': {
      padding: 0
    },

    '&.white': {
      backgroundColor: 'white',

      '& $container': {
        border: 'none'
      }
    }
  },

  containerFF: {
    [theme.breakpoints.up('slg')]: {
      maxWidth: '1082px'
    },

    [theme.breakpoints.down('sm')]: {
      padding: '18px 0'
    },
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    minHeight: '680px',
    padding: '25px 50px',
    borderRadius: '8px',
    border: 'solid 1px #ededed',

    [theme.breakpoints.up('slg')]: {
      maxWidth: '1082px'
    },

    [theme.breakpoints.down('sm')]: {
      padding: '18px 0'
    },
  },

  tripsContainer: {
    marginBottom: '135px'
  },

  errorBlock: {
    display: 'flex',
    justifyContent: 'center',
    flex: '1',
    alignItems: 'center',
    alignSelf: 'stretch',
    justifySelf: 'stretch',
    color: theme.palette.secondary.main,
    fontSize: '24px',
    fontWeight: theme.typography.fontWeightBold
  },

  loadingBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1'
  }
}))

const EmailBookingChooseTrip = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [state, setState] = useState({
    isSent: false,
    loading: true,
    error: false,
    suggestion: null,
    trips: null,
    editingTrip: null,
    tripSelected: false,
    editTransfersData: null,
    bookingState: false,
  })
  let { jwt } = useParams()

  const getCurrentTicket = useCallback(async () => {
    setState({...state, isSent: true, loading: true})
    const resultState = {...state, isSent: true, loading: false}
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      const data = await fetchSuggestion(jwt)
      if (!data?.suggestion) throw new Error('no ticket found')
      resultState.suggestion = {...data.suggestion}
      resultState.error = null
      setState(resultState)
      return true
    } catch (e) {
      resultState.error = 'no ticket found'
      resultState.suggestion = null
      setState(resultState)
      return false
    }
  }, [state, jwt])

  const selectTrip = useCallback( trips => {
    const retDate = moment(state.suggestion?.parsedSourceData?.returnDate, 'YYYY-MM-DD HH:mm')
    const editKey = state.editingTrip

    if (!editKey) {
      return false
    }

    if (
        (editKey !== 'hotel' && !!trips?.outbound && (!!trips?.return || !retDate.isValid())) ||
        (editKey === 'hotel' && !!trips?.hotelData && !!trips?.roomData)
    ) {
      setState({
        ...state,
        editingTrip: editKey !== 'flight' ? null : editKey,
        tripSelected: editKey === 'flight',
        trips: {...(state.trips || {}), [editKey]: {...trips}},
        prevTrip: {...state.trips || {}}
      })
    } else {
      setState({
        ...state,
        editingTrip: null,
        tripSelected: false,
      })
    }
  }, [state])

  const selectFlightServices = useCallback( services => {
    if (state.editingTrip !== 'flight' || !state.trips?.['flight']) {
      return false
    }

    setState({
      ...state,
      editingTrip: null,
      tripSelected: false,
      trips: {
        ...(state.trips || {}),
        flight: {
          ...state.trips.flight,
          ff: services?.ff || null,
          bags: services?.bags || null,
        },
        flightSeats: services?.seats || null
      }
    })
  }, [state])

  const selectFlightSeat = useCallback( seats => {
    setState({
      ...state,
      trips: {
        ...(state.trips || {}),
        flightSeats: seats || null
      }
    })
  }, [state])


  const buildDataForTransfersModal = () => {
    const suggestion = state.suggestion
    const trips = state.trips
    const resTrip = {}
    const flTrip = trips?.flight || suggestion?.tripData?.flight || null
    const railTrip = trips?.rail || suggestion?.tripData?.rail || null
    const transferKeys = []

    if (flTrip) {
      const isEdited = !!trips?.flight
      transferKeys.push('to_flight_outbound');
      transferKeys.push('from_flight_outbound');

      if (!!flTrip?.return) {
        transferKeys.push('to_flight_return');
        transferKeys.push('from_flight_return');
      }

      if (isEdited) {
        resTrip.flight = {
          outbound: flTrip?.outbound?.original?.trip,
          return: flTrip?.return?.original?.returnTrip || null,
        }
      } else {
        resTrip.flight = {
          outbound: flTrip?.outbound?.segments,
          return: flTrip?.return?.segments || null,
        }
      }
    }

    if (railTrip) {
      resTrip.rail = {}

      if (!!railTrip.outbound) {
        transferKeys.push('to_rail_outbound');
        transferKeys.push('from_rail_outbound');

        resTrip.rail.outbound = {
          segments: railTrip.outbound.segments,
          railstart: railTrip.outbound.scheduleSolution.railstart,
          railend: railTrip.outbound.scheduleSolution.railend,
        }
      }

      if (!!railTrip.return) {
        transferKeys.push('to_rail_return');
        transferKeys.push('from_rail_return');

        resTrip.rail.return = {
          segments: railTrip.return.segments,
          railstart: railTrip.return.scheduleSolution.railstart,
          railend: railTrip.return.scheduleSolution.railend,
        }
      }
    }
    const transfers = trips?.transfers || suggestion?.tripData?.transfers || null
    const resTransfers = []
    transferKeys.forEach(tk => {
      if (!transfers?.[tk]?.length) return false;
      const tmpTr = transfers[tk]
      tmpTr.forEach(trData => {
        if (!trData || typeof trData !== 'object') {
          return false;
        }
        const foundU = (suggestion?.passengers || []).find(p => {
          return !!p.uind && p.uind === (trData.userInd || null)
        })

        if (!foundU) {
          return false;
        }
        resTransfers.push({
          ...trData,
          passenger: foundU.uniqueId,
          isPending: true
        })

      })
    })

    if (!resTrip) return false

    return {
      trip: resTrip,
      transfers: resTransfers
    }
  }
  const skipEditing = useCallback(() => {
    setState({...state, editingTrip: null, editTransfersData: null})
  }, [state])

  const editTransfers = useCallback((res) => {
    const tr = res.details || [];
    let results = null

    let error = false
    tr.forEach(newT => {
      if (!newT?.transfer?.length || !newT?.meta?.index || !newT?.meta?.connection) return false;
      if (!results) results = {}
      results[newT.meta.index] =  [...newT.transfer].map(t => {
        if (!t.passenger || typeof t.passenger !== 'string') error = 'invalid transfer user'
        const foundU = (state.suggestion?.passengers || []).find(p => {
          return !!p.uniqueId && p.uniqueId === (t.passenger || null)
        })
        if (!foundU) error = 'invalid transfer user'
        return {
          ...t,
          userInd: foundU.uind,
          connection: newT.meta.connection
        }
      })
    })

    const newTrips = {...(state.trips || {})}
    if (!error) {
      if (!!results) newTrips.transfers = {...results}
    }
    setState({...state, trips: newTrips, editingTrip: null, editTransfersData: null})
  }, [state])


  const onEdit = (type) => {
    if (type === 'transfer') {
      const transferData = buildDataForTransfersModal()
      if (!transferData) return false;
      setState({...state, editingTrip: type, editTransfersData: transferData})
      return true
    }
    setState({...state, editingTrip: type, editTransfersData: null})
  }

  const isError = !state.loading && !!state.error
  const isEditing = state.editingTrip;
  const tripSelected = !isError && !state.loading && !!state.tripSelected
  const isTripsList = (
    !isError &&
    !state.loading &&
    !!isEditing &&
    !tripSelected &&
    !!state.suggestion &&
    ['rail', 'flight'].includes(isEditing)
  )
  const isFFSelect = !isError && !state.loading && !!tripSelected && state.editingTrip === "flight"
  const isHotelEditing = isEditing === 'hotel' && !!state?.suggestion && !state.loading && !isError

  const goBack = () => {
    const newState = {...state, tripSelected: false}
    if (isFFSelect) {
      const editKey = state.editingTrip
      const prev = (!!state?.prevTrip?.[editKey] && typeof state.prevTrip[editKey] === 'object') ? {...state.prevTrip[editKey]} : null
      if (!!newState?.trips?.[editKey]) newState.trips = {...newState.trips, [editKey]: prev || undefined};
    } else if (isTripsList || isHotelEditing) {
      newState.editingTrip = null;
    } else { return false }
    setState(newState)
  }

  useEffect(() => {
    if (!state.isSent) getCurrentTicket()
  }, [state, getCurrentTicket])

  const renderLoading = useCallback( () => (
    <Container disableGutters className={classes.container}>
      <Box key="loading-block" className={classes.loadingBlock}>
        <CircularProgress/>
      </Box>
    </Container>
  ), [classes])

  const renderError = useCallback( () => (
    <Container disableGutters className={classes.container}>
      <Box className={classes.errorBlock}>
        {t('suggestion not found')}
      </Box>
    </Container>
  ), [classes])

  const renderTrips = useCallback( () => (
    <Container disableGutters className={`${classes.container} ${classes.tripsContainer}`}>
      <AutomateBookingRailTrip
        selectTrip={selectTrip}
        suggestion={state.suggestion}
        jwt={jwt}
        tripType={state.editingTrip}
      />
    </Container>
  ), [state, jwt, selectTrip, classes])

  const renderFFSelect = useCallback( () => (
    <Container disableGutters className={classes.containerFF}>
      <SelectFlightFares
        trip={{outboundTrip: state.trips.flight.outbound, returnTrip: state.trips.flight.return}}
        ticketData={state.suggestion}
        passengers={state.suggestion.passengers}
        token={jwt}
        cancel={() => {}}
        select={selectFlightServices}
      />
    </Container>
  ), [state, jwt, selectFlightServices, classes])

  const renderTripsData = useCallback( () => (
    <SelectedTripsData
      suggestion={state.suggestion}
      selectFlightSeat={selectFlightSeat}
      trips={state.trips}
      jwt={jwt}
      onEdit={onEdit}
      setBookingState={(bState) => setState({...state, bookingState: bState})}
    />
  ), [state, classes, jwt])

  const getHeaderIcons = () => {
    const icons = []
    if (!!state.suggestion?.tripData?.flight) {
      icons.push(<FlightIcon key={'header-flight-icon'} className={classes.headerIcon}/>)
    }

    if (!!state.suggestion?.tripData?.rail) {
      icons.push(<RailIcon key={'header-rail-icon'} className={classes.headerIcon}/>)
    }

    if (!!state.suggestion?.tripData?.transfers) {
      icons.push(<TransferIcon key={'header-transfer-icon'} className={classes.headerIcon}/>)
    }

    return icons
  }
  return (
    <Box key={'root'} className={classes.root}>
      <Box key={'header'} className={classes.header}>
        <Box key={"header-left-block"} className={classes.headerSideBlock}>
          {(!!isFFSelect || !!isTripsList || !!isHotelEditing) && (
            <Box key="back-arrow-outer" className={classes.backButtonOuter} onClick={goBack}>
              <ArrowBackIcon color="primary" />
            </Box>
          )}
        </Box>
          <Box key={'header-center-block'} className={classes.headerCenterBlock}>
            {!state.loading && !state.bookingState && (
              <Fragment>
                {getHeaderIcons()}
                <span className={classes.headerText}>
                  {t('trip to')} {_.startCase(_.trim(
                      state.suggestion?.parsedSourceData?.destLocation || state.suggestion?.parsedSourceData?.hotelCity || ''
                  ))}
                </span>
              </Fragment>
            )}
          </Box>
        <Box key={"header-right-block"} justifyContent={'flex-end'} className={classes.headerSideBlock}>
          <LanguageChooser/>
        </Box>
      </Box>
      <Box key={'contentRoot'} className={`${classes.contentRoot} ${!!isEditing && `editing-${isEditing}`} ${!!isHotelEditing || !isEditing ? 'white' : ''}`}>
        {!!state.loading && renderLoading()}
        {!!isError && renderError()}
        {!!isTripsList && renderTrips()}
        {(!isEditing || isEditing === 'transfer') && !state.loading && !isError && renderTripsData()}
        {isHotelEditing && (
          <AutomateBookingHotelSearch suggestion={state.suggestion} onSelect={selectTrip}/>
        )}
        {!!isFFSelect && renderFFSelect()}
      </Box>
      {!!state.editTransfersData && (
        <TransferModal
          trip={state.editTransfersData.trip}
          addedTransfers={state.editTransfersData.transfers}
          modalBoolean={!!state.editTransfersData && state.editingTrip === 'transfer'}
          onClose={skipEditing}
          skipLocalStorage={true}
          users={state.suggestion.passengers}
          onSelect={editTransfers}
          taxiOnly={true}
        />
      )}
    </Box>
  )
}

export default EmailBookingChooseTrip

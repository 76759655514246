import React, {
  createContext,
  useCallback,
  useContext,
  useEffect, useMemo,
  useState,
} from 'react'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useDispatch, useSelector } from 'react-redux'
import { setShowHotelMap } from '../../../store/hotels/hotelsAction'
import MobileView from './hotelSearchMobileView'
import DesktopView from './hotelSearchDesktopView'
import HotelFilterDrawer from '../filters/hotelFilterDrawer'
import HotelMiddlestepFilter from '../filters/hotelMiddlestepFilter/hotelMiddlestepFilter'
import _ from 'lodash'
import { STARS } from '../../../constants/hotelRoomCodes'
import { starsCount } from '../filters/starsFilter'
import { optionUserRatingFilters } from '../../../components/hotels/usersRating'
import { useTranslation } from 'react-i18next'
import { MiddlestepDrawerContext } from '../../../pages/middlestep/middlestep'
import isIframed from '../../../utils/isIframed'

export const DrawerContext = createContext([{}, () => {}])

const HotelSearchContent = ({ lat, lng, isMiddleStep, searchFilters = null, onSelect, onSelectFilter, mapFieldPosition }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  const [drawer, setDrawer] = useState({ left: false })
  const [filterDrawer, setFilterDrawer] = useState(false)
  const ratings = useSelector((state) => state?.hotels?.ratings)
  const filters = useSelector((state) => state?.hotels?.filters)
  const [activeFilters, setActiveFilters] = useState(0)
  const search = useSelector((store) => store?.hotels?.search)
  const showMap = useSelector((store) => store?.hotels?.showMap)
  const { rightDrawer, toggleRightDrawer } = useContext(MiddlestepDrawerContext)

  const toggleSearchDrawer = useCallback(() => {
    if (typeof (toggleRightDrawer) === 'function') {
      toggleRightDrawer()
    } else {
      setFilterDrawer(!filterDrawer)
    }
  }, [toggleRightDrawer, setFilterDrawer, filterDrawer])

  const searchDrawer = useMemo(() => {
    if (typeof (toggleRightDrawer) === 'function') {
      return rightDrawer
    } else {
      return filterDrawer
    }
  }, [toggleRightDrawer, filterDrawer, rightDrawer])

  const maxPriceInArray = React.useCallback(
    () => _.maxBy(search || [], (el) => el?.pricePerNight),
    [search]
  )

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setDrawer({ ...drawer, [anchor]: open })
  }

  useEffect(() => {
    let active = 0
    if (
      filters.popular.wifi ||
      filters.popular.cancellation ||
      filters.popular.breakfast
    ) {
      active++
    }
    if (!_.values(filters.rating).every((el) => el)) {
      active++
    }
    if (!_.values(filters.stars).every((el) => el)) {
      active++
    }
    if (
      filters.maxPrice !== Number(maxPriceInArray()?.pricePerNight) &&
      filters.maxPrice !== 10000000000
    ) {
      active++
    }

    setActiveFilters(active)
  }, [filters, maxPriceInArray])

  const filterHotels = useCallback(() => {
    const filterWifi = search.filter(
      (el) => !filters.popular.wifi || el?.freeWifi
    )
    const filterCancellation = filterWifi.filter(
      (el) => !filters.popular.cancellation || el?.cancellationType === 'free'
    )
    const filterBreakfast = filterCancellation.filter(
      (el) =>
        !filters.popular.breakfast ||
        (el?.breakfastIncluded && el?.breakfastIncluded !== '0')
    )
    const filterEco = filterBreakfast.filter(
      (el) => !filters.popular.eco || el.environmentallyFriendly
    )

    const filterPrice = filterEco.filter(
      (el) => el?.pricePerNight <= filters.maxPrice
    )

    const filterStars = filterPrice.filter((el) => {
      let stars = el?.CachedData?.LOCALRATING
        ? parseInt(el?.CachedData.LOCALRATING)
        : STARS[el?.CachedData?.SELFRATING]

      if (stars == null) stars = 1
      return starsCount.some((star) => filters.stars[star] && stars === star)
    })

    const finishedFiltered = filterStars.filter((el) => {
      const rating = Array.isArray(ratings)
        ? ratings.find(
            (x) => x?.name?.toLowerCase() === el?.title?.toLowerCase()
          )
        : ''
      if (rating?.rating) {
        const result = rating
          ? optionUserRatingFilters(t)[Math.floor(rating.rating * 2) / 2]
          : {}
        if (result) return filters.rating[result.slug]
        else return false
      }
      return filters.rating.no
    })

    return finishedFiltered
  }, [filters, search, ratings, t])

  useEffect(() => {
    if (isMobile) {
      dispatch(setShowHotelMap(false))
    }
  }, [isMobile, dispatch])

  const toggleMap = () => {
    dispatch(setShowHotelMap(!showMap))
  }

  const [filteredSearch, setFilteredSearch] = useState([])

  useEffect(() => {
    setFilteredSearch(filterHotels(search))
  }, [filterHotels, search])

  return (
    <DrawerContext.Provider
      value={{ drawer, toggleDrawer, filteredSearch, activeFilters, searchFilters: searchFilters, onSelect, toggleSearchDrawer }}
    >
      {isMobile ? (
        <MobileView
          isMobile={isMobile}
          isMiddleStep={isMiddleStep}
          search={search}
          lat={lat}
          lng={lng}
          toggleMap={toggleMap}
          showMap={showMap && !isIframed}
        />
      ) : (
        <DesktopView
          isMobile={isMobile}
          isMiddleStep={isMiddleStep}
          search={search}
          lat={lat}
          lng={lng}
          toggleMap={toggleMap}
          showMap={showMap && !isIframed}
          mapFieldPosition={mapFieldPosition}
        />
      )}
      <HotelFilterDrawer drawer={drawer} toggleDrawer={toggleDrawer} />
      {isMiddleStep && (
        <HotelMiddlestepFilter
          drawer={searchDrawer}
          toggleDrawer={toggleSearchDrawer}
          onSelect={onSelectFilter}
        />
      )}
    </DrawerContext.Provider>
  )
}

export default HotelSearchContent

import React, {Fragment, useCallback, useEffect, useState, useMemo} from 'react'
import {Box, Button, CircularProgress, Grid} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { fetchTripsByToken } from '../../repositories/suggestions'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import { getBrandedUrl } from '../../utils/branding'
import {useTranslation} from 'react-i18next'
import axios from 'axios'
import RailTripSearchItem from '../../containers/trains/trainContainers/trainSearchItem/RailTripSearchItem'
import FlightTripSearchItem from './FlightTripSearchItem'
import FiltersLine from './FilterLine'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { TRAIN_COMPANIES } from '../../constants/trainCompanies'
import flightCompanies from '../../constants/flightCompanies'
import {POLICY_RESULT} from "../../constants/policy";
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  tripEditOuter: {
    minHeight: 'calc(100vh - 65px)',
    borderRadius: '4px',
    backgroundColor: 'white',
    width: '100%'
  },

  filtersLine: {
    marginBottom: '23px'
  },

  openIcon: {
    color: theme.palette.primary.main,
    marginLeft: '13px'
  },

  topHeaderRow: {
    lineHeight: 'normal',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      paddingBottom: '23px',
      borderBottom: 'solid 1px #ededed'
    }
  },

  infoOuter: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 16px'
    }
  },

  closeInfoRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px',
  },

  closeInfoLine: {
    flex: 1,
    height: '1px',
    backgroundColor: '#ededed'
  },

  closeInfoBtn: {
    minHeight: '30px',
    padding: '5px 15px',
    border: 'solid 1px #ededed',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold'
  },

  topHeaderLeft: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    }
  },

  showInfoRow: {
    padding: '15px 0',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    fontWeight: 'bold',
    borderBottom: 'solid 1px #ededed',
    marginBottom: '20px',
    cursor: 'pointer'
  },

  mainHeader: {
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: 'normal'
  },

  logoImage: {
    height: '58px',
    width: 'auto'
  },

  topHeaderRight: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer'
  },

  cancelLinkText: {
    fontSize: '16px',
    fontWeight: 'normal'
  },

  errorBlock: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '24px',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.secondary.main
  },

  tripInfoRow: {
    marginBottom: '37px',
    marginTop: '34px',
    display: 'flex',
    alignItems: 'start',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginTop: '27px',
      marginBottom: '46px'
    }
  },

  classTitle: {
    fontSize: '14px',
    lineHeight: 'normal',
    textAlign: 'center',
    color: '#000'
  },

  classesTextRow: {
    marginBottom: '19px'
  },

  tripInfoBlock: {
    '&.trip-info-from': {
      marginRight: '90px',
    },

    '&.trip-info-to': {
      marginRight: '70px'
    },

    '&.trip-info-dep': {
      marginRight: '60px'
    },

    '&:last-child': {
      marginRight: 0
    },

    [theme.breakpoints.down('sm')]: {
      marginBottom: '16px',

      '&:last-child': {
        marginBottom: 0
      },

      '&.trip-info-from, &.trip-info-to, &.trip-info-dep': {
        marginRight: 0
      },
    }
  },

  tripInfBlockTitle: {
    fontSize: '11px',
    fontWeight: 'normal',
    lineHeight: 'normal',
    marginBottom: '2px'
  },

  tripInfoBlockValue: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: 'normal'
  },

  loadingBlock: {
    display: 'flex',
    width: '100%',
    marginTop: '25px',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

const AutomateBookingRailTrip = ({ suggestion, jwt, selectTrip, tripType = 'rail' }) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const depLocKey = tripType === 'rail' ? 'depLocation' : 'flightDepLocation'
  const destLocKey = tripType === 'rail' ? 'destLocation' : 'flightDestLocation'

  const dispatch = useDispatch()
  const [state, setState] = useState({
    dir: 'outbound',
    mobileInfoOpen: true,
    isMounted: false,
    loading: true,
    selectedOutbound: null,
    error: null,
    trips: []
  })
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [filters, setFilters] = useState({
    stops: null,
    airlines: null,
    orderBy: 'departure'
  })

  const [possibleFilters, setPossibleFilters] = useState({
    stops: [],
    airlines: [],
    orderBy: [
      {val: 'price', text: 'price'},
      {val: 'departure',  text: 'sorted departure'},
      {val: 'arrival',  text: 'sorted arrival'},
      {val: 'fast',  text: 'fastest'},
      {val: 'co2',  text: 'co2'}
    ],
  })

  const getMinSeatPrice = useCallback((key = null, item) => {
    let minPrice = null
    const iterableSeats = item?.solutions?.seats || {}

    for (let classKey in iterableSeats) {
      if (!!key && classKey !== key && classKey !== `${key}_cs`) continue
      const classSeats = iterableSeats[classKey] || {}

      for (let seatKey in classSeats) {
        const solution = classSeats[seatKey]
        if (!solution?.price?.Amount) continue
        const price = parseInt(solution.price.Amount)
        if (minPrice === null || minPrice > price) minPrice = price
      }
    }
    return minPrice
  },[])

  const getMinBerthPrice = useCallback(
  (keys = null, item) => {
    let minPrice = null
    const iterableBerth = item?.solutions?.berth
    const keysArray = !!keys && typeof keys === 'string' ? [keys] : keys

    for (let descrKey in iterableBerth) {
      if (!!descr && descr !== descrKey) continue
      const classBerth = iterableBerth?.[descrKey] || {}
      for (let classKey in classBerth) {
        if (!!keysArray?.length && !keysArray.includes(classKey)) continue
        const flexBerth = classBerth[classKey] || {}
        for (let flexKey in flexBerth) {
          const solution = flexBerth[flexKey]
          if (!solution?.price?.Amount) continue
          const price = parseInt(solution.price.Amount)

          if (
              solution.exceedsPolicy === POLICY_RESULT.BLOCK ||
              solution.exceedsPolicy === POLICY_RESULT.HIDE
          )
            continue

          if (minPrice === null || minPrice > price) minPrice = price
        }
      }
    }
    return minPrice
  },[])

  const getRailMinPrice = useCallback((item) => {
    const key = '002'
    let minPrice = getMinSeatPrice(key, item)
    const berthMinPrice = getMinBerthPrice(key, item)
    if (!minPrice && !berthMinPrice) return null
    return !minPrice || (!!berthMinPrice && berthMinPrice < minPrice)
        ? berthMinPrice
        : minPrice
  }, [getMinSeatPrice, getMinBerthPrice])

  const getRailDuration = useCallback((item, fallback = 0) => {
    const startDate = item?.schedule?.scheduleSolution?.railstart?.dateTime
    const endDate = item?.schedule?.scheduleSolution?.railend?.dateTime
    const sMoment = moment(startDate)
    const eMoment = moment(endDate)
    if (!sMoment.isValid() || !eMoment.isValid()) return fallback
    const diffMin = eMoment.diff(sMoment, 'minutes')

    return diffMin || fallback
  }, [])

  const filteredTrips = useMemo(() => {
    return state.trips.filter(t => {
      if (!!filters.stops) {
        const tmpStops = !!filters.stops?.length ? filters.stops : [filters.stops]
        let via = []
        if (tripType === 'flight') {
          via = (state.dir === 'return') ? t.original.returnTrip : t.original.trip
        } else if (tripType === 'rail') {
          via = t?.schedule?.segments
        }
        const tStops = (via?.length > 1) ? via.length - 1 : 'direct'
        if (!tmpStops.includes(tStops)) {
          return false
        }
      }

      const fTripCmp = filters.airlines;
      const tmpCmp = !fTripCmp ? [] : (Array.isArray(fTripCmp) ? fTripCmp : [fTripCmp])
      const tripCompany = tripType === 'flight' ? t?.company : t?.schedule?.scheduleSolution?.serverProviderCode
      if (!tmpCmp.includes(tripCompany)) {
        return false
      }

      return true
    }).sort((a, b) => {
      switch (filters.orderBy) {
        case 'departure': {
          const aDep = tripType === 'flight' ? a.departure : a.schedule?.scheduleSolution?.railstart?.dateTime;
          const bDep = tripType === 'flight' ? b.departure : b.schedule?.scheduleSolution?.railstart?.dateTime;
          return (!!aDep ? moment(aDep).unix() : 0) - (!!bDep ? moment(bDep).unix() : 0)
        }
        case 'arrival': {
          const aArr = tripType === 'flight' ? a.arrival : a.schedule?.scheduleSolution?.railend?.dateTime;
          const bArr = tripType === 'flight' ? b.arrival : b.schedule?.scheduleSolution?.railend?.dateTime;
          return (!!aArr ? moment(aArr).unix() : 0) - (!!bArr ? moment(bArr).unix() : 0)
        }
        case 'fast': {

          let aTime = a.travelTime || 99999;
          let bTime = b.travelTime || 99999;
          if (tripType === 'rail') {
            aTime = getRailDuration(a, 99999);
            bTime = getRailDuration(b, 99999);
          } else {
            aTime = a.travelTime || 99999;
            bTime = b.travelTime || 99999;
          }

          return aTime - bTime
        }
        case 'co2': {
          let aCo2 = 99999;
          let bCo2 = 99999;

          if (tripType === 'flight') {
            aCo2 = a.co2 || 99999;
            bCo2 = b.co2 || 99999;
          } else {
            aCo2 = a.schedule?.co2 || 99999;
            bCo2 = b.schedule?.co2 || 99999;
          }

          return aCo2 - bCo2
        }
        case 'price':
        default: {
          let aPrice = 0
          let bPrice = 0
          if (tripType === 'flight') {
            aPrice = parseFloat(a.price || 0)
            bPrice = parseFloat(b.price || 0)
          } else {
            aPrice = getRailMinPrice(a)
            bPrice = getRailMinPrice(b)
          }

          return (aPrice || 0) - (bPrice || 0)
        }
      }
    })
  }, [state.trips, state.dir, getRailMinPrice, filters, getRailDuration, tripType])

  const lowestPrice = useMemo(() => {
    let minPrice = state.trips?.length ? state.trips.reduce((prev, curr) => {
      let currPrice = 0
      if (tripType === 'flight') {
        currPrice = parseFloat(curr?.price || 0)
      } else {
        currPrice = getRailMinPrice(curr)
      }
      return !!prev && prev < currPrice ? prev : currPrice
    }, null) : 0

    return minPrice || 0

  }, [state.trips, getRailMinPrice, tripType])

  const setFilterOptions = (trips = []) => {
    const newStops = [];
    const newAirlines = [];
    const airlineVals = [];
    trips.forEach(trip => {
      let via = []
      if (tripType === 'flight') {
        via = (state.dir === 'return') ? trip.original.returnTrip : trip.original.trip
      } else if (tripType === 'rail') {
        via = trip?.schedule?.segments
      }
      const tStops = (via?.length > 1) ? via.length - 1 : 'direct'
      const foundStops = newStops.find(s => s.val === tStops)
      if (!foundStops) {
        newStops.push({
          val: tStops,
          text: tStops !== 'direct' ? 'stops' : 'filter directly'
        })
      }
      const tripCompany = tripType === 'flight' ? trip?.company : trip?.schedule?.scheduleSolution?.serverProviderCode
      const alStops = newAirlines.find(s => s.val === tripCompany)
      if (!!tripCompany && !alStops) {
        airlineVals.push(tripCompany)
        let aText = null
        if (tripType === 'flight') {
          aText = flightCompanies?.[tripCompany]?.label || tripCompany
        } else {
          aText = TRAIN_COMPANIES?.[tripCompany] || tripCompany
        }
        newAirlines.push({val: tripCompany, text: `${aText} (${tripCompany})`})
      }
      return true
    })
    const newStopValues = newStops.map(s => s.val)
    setFilters({
      stops: newStopValues?.length ? newStopValues : null,
      airlines: [...airlineVals],
      orderBy: 'departure',
    })

    setPossibleFilters({
      stops: [...newStops],
      airlines: [...newAirlines],
      orderBy: [
        {val: 'price', text: 'price'},
        {val: 'departure',  text: 'sorted departure'},
        {val: 'arrival',  text: 'sorted arrival'},
        {val: 'fast',  text: 'fastest'},
        {val: 'co2',  text: 'co2'}
      ]
    })

  }

  const preparedPosFilters = useMemo(() => {
    const tmpFilters = _.cloneDeep(possibleFilters)
    if (tmpFilters.stops?.length) {
      tmpFilters.stops = tmpFilters.stops.map((f) => {
        return {
          ...f,
          text: f.val !== 'direct' ? t(f.text, {count: f.val}) : t(f.text)
        }
      })
    }
    tmpFilters.orderBy = tmpFilters.orderBy.map((f) => ({...f, text: t(f.text)}))
    return tmpFilters
  }, [i18n.language, possibleFilters, t])

  const getTrips = async () => {
    window.scrollTo(0, 0);
    setState({...state, loading: true, isMounted: true, trips: []})
    const resultState = {isMounted: true, loading: false, trips: []}
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      const langMatch = (i18n.language || '').match(/(\w{2})-(\w{2})/i)
      const lang = langMatch?.[1] || i18n.language

      const rqData = { direction: state.dir, tripType: tripType, lang: lang}

      if (state.dir === 'return' && !!state.selectedOutbound && tripType === 'rail') {
        rqData.bookingToken = state.selectedOutbound.solution.bookingToken
        rqData.correlationID = state.selectedOutbound.correlationID
      } else if (state.dir === 'return' && !!state.selectedOutbound && tripType === 'flight') {
        rqData.outboundData = {
          searchToken: state.outboundSearchToken || null,
          outboundTrip: {
            original: state.selectedOutbound.original
          },
        }
      }
      setFilterOptions()
      const data = await fetchTripsByToken(jwt, rqData, 'tickets')

      if (tripType === 'rail') {
        if (!data?.list?.length) throw new Error('no trips found')
        resultState.trips = (data?.list?.length) ? [...data.list] : []
        setFilterOptions(resultState.trips)
        resultState.error = null
        setState({...state, ...resultState})
      } else if (tripType === 'flight') {
        if (!data?.list?.data?.length) throw new Error('no trips found')
        resultState.trips = (data?.list?.data?.length) ? [...data.list.data] : []
        setFilterOptions(resultState.trips)
        resultState.error = null
        resultState.outboundSearchToken = data?.list?.searchToken
        setState({...state, ...resultState})
      }
      return true
    } catch (e) {
      if (!axios.isCancel(e)) {
        resultState.error = 'no ticket found'
        resultState.trips = []
        setState({...state, ...resultState})
      }
      return false
    }
  }

  useEffect(() => {
    getTrips()
  }, [state.dir])

  const toggleInfo = useCallback((isOpen) => {
    setState({...state, mobileInfoOpen: !!isOpen})
  }, [state])

  const onSelectTicket = useCallback(ticket => {
    if (!ticket) return false
    if (state.dir === 'outbound') {
      const retDate = moment(suggestion?.parsedSourceData?.returnDate, 'YYYY-MM-DD HH:mm')

      if (retDate.isValid()) {
        setState({...state, selectedOutbound: {...ticket}, selectedReturn: null, dir: 'return'})
      } else {
        selectTrip({outbound: {...ticket}, return: null})
      }
    } else if (state.dir === 'return') {
      selectTrip({outbound: {...state.selectedOutbound}, return: {...ticket}})
    }
  }, [state, getTrips])

  const getTrainClass = useCallback(() => {
    const parsed = suggestion?.parsedSourceData
    if (tripType === 'flight') {
      if (!parsed?.flightClassKey || !parsed?.flightBagKey) return ''
      return t(`${parsed?.flightClassKey} ${parsed?.flightBagKey}`)
    }

    const isParsedTkt = !!parsed?.railClassKey && !!parsed?.railFlexKey
    let tktLine = ''

    if (isParsedTkt) {
      const clExplode = parsed.railClassKey.split('_')
      const classNumText = clExplode?.[1] === 'cs' ? t('class calm') : t('class')
      const classNum = clExplode?.[1] === '001' ? '1' : '2'
      tktLine = `${classNum} ${classNumText} ${t(parsed.railFlexKey)}`
    } else {
      tktLine = parsed?.trainClass
    }
    return tktLine
  }, [suggestion, t])

  const changeFilters = (newFilters) => {
    if (!newFilters || typeof newFilters !== 'object') {
      return false
    }
    setFilters(newFilters)
  }

  const depDate = useMemo(() => {
    const depDateKey = tripType === 'rail' ? 'depDate' : 'flightDepDate'
    const retDateKey = tripType === 'rail' ? 'returnDate' : 'flightReturnDate'
    const depDateText = state.dir === 'outbound' ? suggestion?.parsedSourceData?.[depDateKey] : suggestion?.parsedSourceData?.[retDateKey]
    const depDate = moment(depDateText, 'YYYY-MM-DD HH:mm')
    return !depDate.isValid() ? null : depDate.format('dddd D MMM HH:mm')
  }, [suggestion, state, tripType])

  const curTripData = useMemo(() =>{
    return suggestion?.tripData?.[tripType]?.[state.dir]
  }, [suggestion, state.dir, tripType])

  return (
    <Box key="tkt-trip-edit-outer" className={classes.tripEditOuter}>
      {!!suggestion && (
        <Fragment>
          <Box key={'info-outer-block-row'} className={classes.infoOuter}>
            <Box key="top-header-row" className={classes.topHeaderRow}>
              <Box key="top-header-text-block" className={classes.topHeaderLeft}>
            <span key="header-text" className={classes.mainHeader}>
              {t(state.dir === 'outbound' ? 'select outbound trip' : 'select return trip')}
            </span>
              </Box>
              {!isMobile && (
                <Box key="top-header-right-block" className={classes.topHeaderRight}>
                  <img src={getBrandedUrl('logo_retina.png')} className={classes.logoImage}/>
                </Box>
              )}
            </Box>
            {(state.mobileInfoOpen || !isMobile) &&
              <Fragment>
                <Box key="trip-info-row" className={classes.tripInfoRow}>
                  <Box key="trip-info-from" className={`${classes.tripInfoBlock} trip-info-from`}>
                    <Box key="trip-info-block-title"
                         className={classes.tripInfBlockTitle}>{_.capitalize(t('from'))}</Box>
                    <Box key="trip-info-block-value" className={classes.tripInfoBlockValue}>
                      {state.dir === 'outbound' ? suggestion.parsedSourceData[depLocKey] : suggestion.parsedSourceData[destLocKey]}
                    </Box>
                  </Box>

                  <Box key="trip-info-to" className={`${classes.tripInfoBlock} trip-info-to`}>
                    <Box key="trip-info-block-title" className={classes.tripInfBlockTitle}>{t('search to')}</Box>
                    <Box key="trip-info-block-value" className={classes.tripInfoBlockValue}>
                      {state.dir === 'outbound' ? suggestion.parsedSourceData[destLocKey] : suggestion.parsedSourceData[depLocKey]}
                    </Box>
                  </Box>

                  <Box key="trip-info-dep" className={`${classes.tripInfoBlock} trip-info-dep`}>
                    <Box key="trip-info-block-title" className={classes.tripInfBlockTitle}>{t('departure')}</Box>
                    <Box key="trip-info-block-value" className={classes.tripInfoBlockValue}>{depDate}</Box>
                  </Box>

                  <Box key="trip-info-ticket" className={`${classes.tripInfoBlock} trip-info-block`}>
                    <Box key="trip-info-block-title" className={classes.tripInfBlockTitle}>{t('ticket')}</Box>
                    <Box key="trip-info-block-value" className={classes.tripInfoBlockValue}>{getTrainClass()}</Box>
                  </Box>
                </Box>
                {isMobile &&
                  <Box key="close-info-row" className={classes.closeInfoRow}>
                    <Box key="close-info-left-line" className={classes.closeInfoLine}/>
                    <Box key="close-info-button" className={classes.closeInfoBtn}
                         onClick={() => toggleInfo(false)}>
                      {t('detail collapse')}
                    </Box>
                    <Box key="close-info-right-line" className={classes.closeInfoLine}/>
                  </Box>
                }
              </Fragment>
            }
            {!state.mobileInfoOpen && isMobile &&
              <Box key="show-info-row" className={classes.showInfoRow} onClick={() => toggleInfo(true)}>
                <span>{t('view suggestion search details')}</span> <KeyboardArrowDownIcon key="open-icon" className={classes.openIcon}/>
              </Box>
            }
          </Box>

          {!state.loading && (
            <div key="filters-line" className={classes.filtersLine}>
              <FiltersLine tripType={tripType} filters={filters} onSelect={changeFilters} possibleFilters={preparedPosFilters}/>
            </div>
          )}

          {!!state.loading &&
            <Box className={classes.loadingBlock}>
              <CircularProgress/>
            </Box>
          }

          {!state.loading && !!state.error &&
            <div key={'error-block'} className={classes.errorBlock}>
              {t('trips not found')}
            </div>
          }

          {!state.loading && !state.error && !!filteredTrips?.length && (
            <Fragment>
              {!!isMobile && tripType === 'rail' && (
                  <Grid key={'classes-text-row'} container spacing={0} className={classes.classesTextRow}>
                    <Grid key={'classes-text-left'} item xs={6} lg={8}/>
                    <Grid key={'classes-text-right'} item xs={6} lg={4}>
                      <Grid key={'classes-inner-row'} container spacing={0}>
                        <Grid key={'classes-inner-row-left'} item xs={6} className={classes.classTitle}>
                          2 {t('class')}
                        </Grid>
                        <Grid key={'classes-inner-row-right'} item xs={6} className={classes.classTitle}>
                          1 {t('class')}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
              )}
              <div key="trips-list">
                {filteredTrips.map((trip, i) => {
                  if (tripType === 'rail') {
                    return (<RailTripSearchItem key={`trip-item-${i}`} onSelectTicket={onSelectTicket} item={trip} currTrip={curTripData}/>)
                  }

                  if (tripType === 'flight') {
                    return (<FlightTripSearchItem
                      key={`trip-item-${i}`}
                      lowestPrice={lowestPrice}
                      onSelectTicket={onSelectTicket}
                      item={trip}
                      currTrip={curTripData}
                      outbound={state.dir === 'return' && !!state.selectedOutbound ? state.selectedOutbound : null}
                    />)
                  }
                })}
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </Box>
  )
}

export default AutomateBookingRailTrip

import React, {Fragment, useCallback, useEffect, useState, useMemo} from 'react'
import _ from 'lodash'
import {Box, Button, CircularProgress, Grid, Container} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import formatNumber from '../../utils/numberWithCommas'
import {useTranslation} from 'react-i18next'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FlightIcon from '@material-ui/icons/FlightTakeoff'
import RailIcon from '@material-ui/icons/DirectionsRailway'
import TransferIcon from '@material-ui/icons/LocalTaxi'
import HotelIcon from '@material-ui/icons/Hotel';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import {editAndApproveTrips} from "../../repositories/suggestions";
import {BED_TYPE, parseRoomCode, ROOM_CATEGORY} from "../../constants/hotelRoomCodes";
import TripChangedBanner from "./TripChangedBanner";
import flightProviders from "../../constants/flightCompanies";
import {getFlightTripSeats} from "../../repositories/flights";
import SeatReservationModal from "../../containers/modals/seatReservationModal";
import RegularLoader from "../reusable/loaders/regularLoader";

const typeIcons = {
  flight: FlightIcon,
  rail: RailIcon,
  hotel: HotelIcon,
  transfer: TransferIcon
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '660px',
    marginTop: '-5px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  bannerOuter: {
    marginBottom: '25px'
  },
  tripsDataOuter: {
    borderTop: '1px solid #e6e6e6',
    borderBottom: '1px solid #e6e6e6',
    padding: '36px 28px',

    [theme.breakpoints.down('sm')]: {
      padding: '20px 10px',
    }
  },
  loadingBlock: {
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  firstAvatar: {
    display: 'inline-flex',
    fontSize: '14px',
    color: 'white',
    lineHeight: 'normal',
    backgroundColor: theme.palette.primary.main,
    width: '32px',
    height: '32px',
    borderRadius: '65px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  secondAvatar: {
    display: 'inline-flex',
    fontSize: '14px',
    color: 'white',
    lineHeight: 'normal',
    backgroundColor: '#ffa634',
    position: 'relative',
    left: '-7px',
    borderRadius: '65px',
    width: '32px',
    height: '32px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  travelers: {
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '1.3em',
    marginRight: '8px',
    color: '#666',
    marginLeft: '16px',
    display: 'flex',
  },

  imageDataRow: {
    marginRight: '30px',

    [theme.breakpoints.down('sm')]: {
      marginRight: '13px',
    }
  },

  tripVertLine: {
    position: 'absolute',
    width: '1px',
    left: '14px',
    top: '37px',
    bottom: '7px',
    backgroundColor: '#0dc5b8',
  },

  infoLabel: {
    display: 'inline-flex',
    padding: '1px 5px',
    fontWeight: '500',
    borderRadius: '3px',
    alignItems: 'center',
    marginRight: '10px',

    '&:last-child': {
      marginRight: 0
    },

    '&.no-window': {
      color: 'white',
      backgroundColor: '#ffd13a'
    },

    '&.out-of-policy': {
      color: '#ff4700',
      backgroundColor: '#ffdacc'
    },
  },
  noRoomLabel: {
    display: 'inline-flex',
    padding: '1px 5px',
    color: 'white',
    fontWeight: '500',
    borderRadius: '3px',
    backgroundColor: ' #ffd13a',
    alignItems: 'center'
  },

  policyLabel: {
    display: 'inline-flex',
    padding: '1px 5px',
    color: '#ff4700',
    fontWeight: '500',
    borderRadius: '3px',
    backgroundColor: '#ffdacc',
    alignItems: 'center'
  },

  tripTypeIcon: {
    width: '26px',
    height: '30px',
    color: '#0dc5b8',
  },

  editBtn: {
    cursor: 'pointer',
    color: '#0dc5b8',
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightBold,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  dataRow: {
    '& $editBtn': {
      marginTop: '15px'
    }
  },

  dateRow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '30px',

    '& $dataRowMainText, & $dataRowMuteText': {
      display: 'inline-flex',
      width: 'auto'
    },

    [theme.breakpoints.down('sm')]: {
      marginRight: '13px',
    }
  },
  dataRowMainText: {
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: '3px',
    display: 'flex',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    }
  },
  dirArrow: {
    height: '22px',
    width: 'auto',
    margin: '0 8px'
  },
  editBtnArrow: {
    height: '14px',
    width: 'auto'
  },
  dataRowMuteText: {
    fontSize: '14px',
    color: '#666',
    fontWeight: 'normal',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',

    '&.green': {
      color: '#38995f',
      fontWeight: theme.typography.fontWeightMedium,
    },

    '& $dirArrow': {
      height: '15px',
      margin: '0 4px'
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    }
  },
  dot: {
    height: '4px',
    width: '4px',
    display: 'inline-flex',
    borderRadius: '8px',
    margin: '0 8px',
    backgroundColor: '#666',

    '&.green': {
      backgroundColor: '#38995f'
    },
  },
  btnRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tripDataBlock: {
    paddingBottom: '50px',
    position: 'relative',

    '&:last-child': {
      paddingBottom: 0,

      '& $tripVertLine': {
        display: 'none'
      }
    }
  },

  priceTotalText: {
    display: 'inline',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      display: 'block',
      lineHeight: '1.44'
    }
  },

  bold: {
    fontWeight: theme.typography.fontWeightBold
  },

  tripBottomBlock: {
    display: 'flex',
    flexDirection: 'column',
    padding: '45px 45px 0px 45px',
    alignItems: 'center',
    fontWeight: 'normal',
    lineHeight: 'normal',
    color: 'black',

    [theme.breakpoints.down('sm')]: {
      padding: '37px 16px 0 16px',
      borderRadius: '8px'
    }
  },

  tripTotalBlock: {
    display: 'inline-block',
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
    marginBottom: '18px'
  },

  bookQuestion: {
    display: 'inline-block',
    fontSize: '18px',
    marginBottom: '30px',
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center'
  },

  buttonsRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },

  approveButton: {
    display: 'flex',
    width: '280px',
    height: '44px',
    borderRadius: '44px',
    backgroundColor: '#ee4c25',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    marginBottom: '23px',
    fontWeight: theme.typography.fontWeightBold,
    color: 'white',
    cursor: 'pointer',
    textDecoration: 'none',
    padding: '0 15px',
    letterSpacing: '0.7px',
    textTransform: 'uppercase'
  },

  logoImage: {
    height: '85px',
    width: 'auto',
    marginBottom: '70px',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  bookingInfoText: {
    fontSize: '17px',
    color: '#0dc5b8',
    fontWeight: theme.typography.fontWeightBold
  },
  resultHeaderBlock: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '20px',
    lineHeight: 'normal',
    color: theme.palette.common.black,
    textAlign: 'center',
    marginBottom: '26px',

    '&.error': {
      color: theme.palette.secondary.main
    },

    [theme.breakpoints.down('sm')]: {
      lineHeight: '1.4',
    }
  },
  checkmarkBlock: {
    width: '45px',
    height: '45px',
    borderRadius: '25px',
    border: '2px solid #34c300',
    marginBottom: '20px',
    position: 'relative'
  },
  circularProgress: {
    marginBottom: '20px',
  },
  checkMark: {
    display: 'inline-block',
    position: 'absolute',
    top: '4px',
    left: '13px',
    height: '27px',
    width: '15px',
    borderRight: '2px solid #34c300',
    borderBottom: '2px solid #34c300',
    transform: 'rotate(45deg)'
  },
  resultText: {
    fontSize: '20px',
    lineHeight: 'normal',
    color: theme.palette.common.black,
    textAlign: 'center',
    marginBottom: '32px',

    '&.error': {
      color: theme.palette.secondary.main
    },

    [theme.breakpoints.down('sm')]: {
      lineHeight: '1.75',
    }
  },
  infoBlock: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

const SelectedTripsData = ({ trips, suggestion, jwt, onEdit, setBookingState, selectFlightSeat}) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const [state, setState] = useState({
    loading: false,
    approveSent: false,
    error: false
  })

  const [seatsLoading, setSeatsLoading] = useState(false)
  const [fetchedSeats, setFetchedSeats] = useState(null)
  const [openSeats, setOpenSeats] = useState(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const approve = useCallback(async () => {
    setState({...state, loading: true})
    if (typeof setBookingState === 'function') {
      setBookingState('booking')
    }
    try {
      const rqData = {}

      if (!!trips?.flight?.outbound) {
        rqData.flight = {
          outboundTrip: trips.flight.outbound,
          returnTrip: trips.flight?.return,
          services: {
            bags: trips?.flight?.bags || false,
            ff: trips?.flight?.ff || false,
          },
          ...(!!trips?.flightSeats ? {seats: trips.flightSeats} : {})
        }
      } else if (!!trips?.flightSeats) {
        rqData.flight = {seats: trips.flightSeats, onlyServices: true}
      }

      if (!!trips?.rail?.outbound) {
        rqData.rail = {
          outboundTrip: trips.rail.outbound,
          returnTrip: trips.rail?.return
        }

        if (!!trips?.rail?.seats) rqData.seats = {
          outbound: trips.rail.seats?.outbound?.seats || null,
          return: trips.rail.seats?.return?.seats || null,
        }
      }

      if (!!trips?.hotel) {
        rqData.hotel = {...trips.hotel}
      }

      if (!!trips?.transfers) {
        rqData.transfers = {...trips.transfers}
      }

      const data = await editAndApproveTrips(jwt, rqData)
      setState({...state, loading: false, approveSent: true, error: false})

      if (typeof setBookingState === 'function') {
        setBookingState('booked')
      }
    } catch (e) {
      setState({...state, loading: false, approveSent: true, error: true})
      if (typeof setBookingState === 'function') {
        setBookingState('bookingFailed')
      }
    }
  }, [state, jwt, trips])

  const flightPrices = useMemo(() => {
    const passengers = suggestion?.passengers
    const isEdited = !!trips?.flight
    const { outbound:outboundTrip, return:returnTrip, ff, bags } = trips?.flight || suggestion?.tripData?.flight || {}

    const isMultiTicket = isEdited ? returnTrip?.original?.isMultiTicket : returnTrip?.isMTK;
    const usersCount = passengers?.length || 1;
    let bagsTotal = 0;
    let bagsTotalPerType = {carryOn: 0, general: 0};
    let bagsCount = {carryOn: 0, general: 0};

    ['outbound', 'return'].forEach((d) => {
      if (!bags?.[d] || typeof bags[d] !== 'object') return false
      const tmpDirBag = bags[d];
      ['carryOn', 'general'].forEach(k => {
        for (let uind in tmpDirBag) {
          if (!tmpDirBag[uind]?.[k]) continue
          bagsCount[k]++
          const tmpOPrice = parseFloat(tmpDirBag[uind]?.[k]?.price || 0)
          bagsTotalPerType[k] += tmpOPrice
          bagsTotal += tmpOPrice
        }
      })
    })


    if (isMultiTicket) {
      let oPrice = parseFloat((isEdited ? returnTrip.original.outboundPrice : outboundTrip.price) || 0)
      if (!!ff?.outbound?.price) oPrice = ff.outbound.price * usersCount

      let rPrice = parseFloat((isEdited ? returnTrip.original.inboundPrice : returnTrip.price) || 0)
      if (!!ff?.return?.price) rPrice = ff.return.price * usersCount

      return {
        bagsCount,
        usersCount,
        trip: oPrice + rPrice,
        bags: bagsTotalPerType,
        total: oPrice + rPrice + bagsTotal,
      }
    } else if (returnTrip) {
      let price = parseFloat(returnTrip?.price || 0)
      if (!!ff?.outbound?.price) {
        price = parseFloat(ff.outbound.price) * usersCount
      }
      return {
        bagsCount,
        usersCount,
        trip: price,
        bags: bagsTotalPerType,
        total: price + bagsTotal
      }
    } else {
      let price = parseFloat(outboundTrip?.price || 0)
      if (!!ff?.outbound?.price) {
        price = parseFloat(ff.outbound.price) * usersCount
      }
      return {
        bagsCount,
        usersCount,
        trip: price,
        bags: bagsTotalPerType,
        total: price + bagsTotal
      }
    }
  }, [suggestion, trips])

  const getRoomCodes = useCallback((code) => {
    const {bedsCode, bedTypeCode, roomCode} = parseRoomCode(code)
    const bedType = BED_TYPE(bedTypeCode, bedsCode > 1);
    const roomType = ROOM_CATEGORY(roomCode);
    return {bedType, roomType, bedsCount: bedsCode}
  },[])

  const getSeats = useCallback(async (type) => {
    if (type !== 'flight') return false
    try {
      const isFlightEdited = !!trips?.flight;
      const flightTrip = trips?.flight || suggestion?.tripData?.flight;
      const { outbound:outboundTrip, return:returnTrip } = flightTrip || {}
      const outgoing = isFlightEdited ? outboundTrip.original.trip : outboundTrip.segments
      const returning = isFlightEdited ? returnTrip?.original?.returnTrip || [] : returnTrip?.segments || []
      const isMultiTicket = returning?.original?.isMultiTicket
      const ff = flightTrip?.ff || null;
      if (!outboundTrip) return false;
      setOpenSeats(true)
      setSeatsLoading(true)
      setFetchedSeats(null)
      const res = await getFlightTripSeats(outgoing, returning, isMultiTicket, {
        outboundFare: ff?.outbound,
        returnFare: ff?.return
      })
      setFetchedSeats(res)
      setSeatsLoading(false)
      setOpenSeats(true)
    } catch (e) {
      setOpenSeats(false)
      setSeatsLoading(false)
      setFetchedSeats(null)
    }
  }, [suggestion, trips])

  const tripsData = useMemo(() => {
    const dirs = ['outbound', 'return']
    let results = []

    const flightTrip = trips?.flight || suggestion?.tripData?.flight;
    const railTrip = trips?.rail || suggestion?.tripData?.rail;
    const hotelTrip = trips?.hotel || suggestion?.tripData?.hotel;

    if (!!flightTrip) {
      const bagKeys = ['BAG', 'COBAG', 'HBAG', 'UBAG'];
      const rbkKeys = [
        'RBK',
        'RBK_CH',
        'RBK_1',
        'NRBK',
      ];
      const refKeys = [
        'REF',
        'REF_CH',
        'NREF',
      ];

      const bags = flightTrip?.bags || false;
      const ff = flightTrip?.ff || false;
      const isFlightEdited = !!trips?.flight;
      dirs.forEach(d => {
        let dirBag = {}
        let dirFF = {}
        let refText = null;
        let rbkText = null;
        const ticketTexts = []
        if (!flightTrip?.[d]) return false
        const tmpData = flightTrip?.[d]

        const isInboundMTK = (d === 'return' && tmpData.isMTK)
        if (!isInboundMTK) {
          dirBag = isFlightEdited ? bags?.outbound || {} : flightTrip?.outbound?.bags || {}
          dirFF = ff?.outbound?.byFlights || {}
        } else {
          dirBag = isFlightEdited ? bags?.return || {} : flightTrip?.return?.bags || {}
          dirFF = ff?.return?.byFlights || {}
        }

        let segments = [];
        if (isFlightEdited) {
          segments = d === 'outbound' ? tmpData.original.trip : tmpData.original.returnTrip
        } else {
          segments = tmpData.segments
        }
        const underTexts = [];
        const uniqueIds = segments.map(s => s.uniqueInd)

        for (let flId in dirFF) {
          if (!uniqueIds.includes(flId)) continue
          const tmpFFSrvs = dirFF?.[flId]?.ffServ || [];
          tmpFFSrvs.forEach(s => {
            if (rbkKeys.includes(s?.type) && !rbkText) {
              ticketTexts.push(t(s.text))
              rbkText = s.text;
            } else if (refKeys.includes(s?.type) && !refText) {
              ticketTexts.push(t(s.text))
              refText = s.text
            }

            if (!bagKeys.includes(s?.type || '')) return false;
            const currText = t(s.text)
            if (underTexts.includes(currText)) return false;
            underTexts.push(currText)
          })
        }

        suggestion?.passengers?.forEach(p => {
          if (!dirBag?.[p.uind]) return false
          const keys = ['carryOn', 'general']

          const bagsObj = dirBag[p.uind]
          keys.forEach(bagTypeKey => {
            const isCO = bagTypeKey === 'carryOn';
            const bag = bagsObj?.[bagTypeKey] || null
            if (!bag || typeof(bag) !== 'object') return false;
            const pt = bag?.pricingType || 'PPR'
            const bgCnt = bag.count
            const dscrTxt = bag.description
            const descr = `${(pt !== 'PPK') ? bgCnt : '' } ${t(dscrTxt, pt === 'PPK' ? {w: bag.w} : {})} (${p.firstName} ${p.lastName})`
            underTexts.push(descr)
          })
        })
        const flightSeats = trips?.flightSeats;
        const seatTexts = [];

        if (!!flightSeats) {
          const seats = flightSeats || {};
          for (let userKey in seats) {
            const p = (suggestion?.passengers || []).find(p => p.uind === userKey)
            const pSeats = []
            const tmpSts = seats[userKey][d]?.seats || {}
            for (let stInd in tmpSts) {
              const s = tmpSts[stInd]
              if (!!s.l && !!s.n) pSeats.push(`${s.n}${s.l}`)
            }
            if (pSeats.length > 0) seatTexts.push(`${pSeats.join(',')} (${p.firstName} ${p.lastName})`)
          }
        } else {
          suggestion?.passengers.forEach(p => {
            const pSeats = []
            const tmpSts = p?.flightSeats?.seats?.[d] || {}
            for (let stInd in tmpSts) {
              const s = tmpSts[stInd]
              if (!!s.l && !!s.n) pSeats.push(`${s.n}${s.l}`)
            }
            if (pSeats.length > 0) seatTexts.push(`${pSeats.join(',')} (${p.firstName} ${p.lastName})`)
          })
        }
        const exceedsPolicy = tmpData?.exceedsPolicy

        if (isFlightEdited) {
          const momentDep = moment(tmpData.departure)
          const depUnix = momentDep.unix()
          results.push({
            type: 'flight',
            dir: d,
            ticketTexts,
            seatTexts,
            exceedsPolicy,
            provider: flightProviders?.[tmpData?.company]?.label || tmpData?.company,
            momentDep,
            momentArr: moment(tmpData.arrival),
            depUnix,
            from: `${tmpData.from.city} (${tmpData.from.shortName})`,
            to: `${tmpData.to.city} (${tmpData.to.shortName})`,
            underTexts,
            segments
          })
        } else {
          const momentDep = moment(tmpData.startDate)
          const depUnix = momentDep.unix()

          results.push({
            type: 'flight',
            dir: d,
            momentDep,
            seatTexts,
            ticketTexts,
            exceedsPolicy,
            provider: tmpData.provider,
            momentArr: moment(tmpData.endDate),
            depUnix,
            from: `${tmpData.fromCity} (${tmpData.fromIata})`,
            to: `${tmpData.toCity} (${tmpData.toIata})`,
            underTexts,
            segments
          })
        }
      })
    }

    const trainFlexByKey = {
      "004": "nonRebookable",
      "003": "rebookable",
      "002": "refundable",
      "005": "refundable",
      "006": "refundable",
      "007": "refundable",
    }

    if (!!railTrip) {
      const isRailEdited = !!trips?.rail;
      dirs.forEach(d => {
        if (!railTrip?.[d]) return false
        const tmpData = railTrip?.[d]
        if (isRailEdited) {
          const scSol = tmpData.scheduleSolution
          const sol = tmpData.solution
          const momentDep = moment(scSol.railstart.dateTime)
          const depUnix = momentDep.unix()
          const exceedsPolicy = sol?.exceedsPolicy;

          results.push({
            type: 'rail',
            dir: d,
            railFlex: trainFlexByKey?.[sol?.flexibility || 'none'] || null,
            railClass: sol.BCLocal,
            exceedsPolicy,
            momentDep,
            momentArr: moment(scSol.railend.dateTime),
            depUnix,
            from: scSol.railstart.locationName,
            to: scSol.railend.locationName,
            segments: tmpData.segments
          })
        } else {
          const momentDep = moment(tmpData.startDate)
          const depUnix = momentDep.unix()
          const exceedsPolicy = tmpData?.exceedsPolicy;

          results.push({
            type: 'rail',
            dir: d,
            railFlex: tmpData.flexKey,
            railClass: tmpData.classKey,
            exceedsPolicy,
            momentDep,
            momentArr: moment(tmpData.endDate),
            depUnix,
            from: tmpData.startLocation,
            to: tmpData.endLocation,
            segments: tmpData.segments
          })
        }
      })
    }


    if (!!hotelTrip) {
      const isFlightEdited = !!trips?.hotel
      const roomCode = !isFlightEdited ?
          hotelTrip?.iniRoomCode :
          hotelTrip?.roomData?.roomInfoCode || hotelTrip?.roomData?.roomTypeCode;
      const roomsCount = !isFlightEdited ? hotelTrip.roomsCount : hotelTrip.roomData.roomsCount;
      const {bedType, roomType, bedsCount} = getRoomCodes(roomCode)
      let roomDataText = []
      roomDataText.push(`${roomsCount} ${t('rooms').toLowerCase()}`)
      if (!!roomType) roomDataText.push(t(roomType))
      if (!!bedType) roomDataText.push(`${bedsCount} ${t(bedType)}`)
      const atMark = t('at');
      const fullFormat = `D MMM, [${atMark}] HH:mm`

      if (!isFlightEdited) {
        const momentDep = moment(hotelTrip.checkIn)
        const depUnix = momentDep.unix()
        const breakfast = hotelTrip?.breakfastIncluded || false
        let freeCancel = false
        const noWindowRoom = hotelTrip?.noWindow || false

        if (hotelTrip?.cancellationType === 'free') {
          freeCancel = moment(hotelTrip.freeCancelDeadline).format(fullFormat)
        }

        const additionalData = []
        additionalData.push(t(!breakfast ? 'breakfast not included' : 'breakfast included'))

        if (!!freeCancel) {
          additionalData.push(`${t('free cancellation')} ${t('until')} ${freeCancel}`)
        }


        results.push({
          type: 'hotel',
          momentDep,
          roomDataText: roomDataText.join(', '),
          momentArr: moment(hotelTrip.checkOut),
          exceedsPolicy: hotelTrip.exceedsPolicy,
          depUnix,
          city: _.capitalize(_.toLower(hotelTrip.cityName || '')),
          hotelName: hotelTrip.hotelName,
          address: hotelTrip.address,
          price: hotelTrip.totalPrice,
          additionalData,
          noWindowRoom,
          underTexts: [
            `${t('hotel search check in')} ${hotelTrip.checkIn}`,
            `${t('hotel search check out')} ${hotelTrip.checkOut}`,
          ]
        })
      } else {
        const checkIn = hotelTrip?.roomData?.timeSpan?.Start;
        const checkOut = hotelTrip?.roomData?.timeSpan?.End;
        const noWindowRoom = hotelTrip?.roomData?.noWindowRoom || false
        const cityName = _.capitalize(_.toLower(hotelTrip?.hotelData?.CachedData?.CITY2 || ''))
        const momentDep = moment(checkIn)
        const depUnix = momentDep.unix()
        const breakfast = hotelTrip.roomData?.breakfastIncluded && hotelTrip.roomData?.breakfastIncluded !== '0'
        let freeCancel = false
        if (hotelTrip?.roomData?.cancellationType === 'free') {
          freeCancel = moment(hotelTrip.roomData.freeCancellationDeadline).format(fullFormat)
        }

        const additionalData = []
        additionalData.push(t(!breakfast ? 'breakfast not included' : 'breakfast included'))

        if (!!freeCancel) {
          additionalData.push(`${t('free cancellation')} ${t('until')} ${freeCancel}`)
        }

        results.push({
          type: 'hotel',
          momentDep,
          exceedsPolicy: hotelTrip?.roomData?.exceedsPolicy,
          additionalData,
          noWindowRoom,
          roomDataText: roomDataText.join(', '),
          momentArr: moment(checkOut),
          depUnix,
          city: cityName,
          hotelName: _.startCase(_.toLower(hotelTrip.hotelData.title)),
          address: hotelTrip.hotelData.location,
          price: hotelTrip.roomData.totalPrice,
          underTexts: [
            `${t('hotel search check in')} ${checkIn}`,
            `${t('hotel search check out')} ${checkOut}`,
          ]
        })
      }
    }

    const res = []
    const transfers = trips?.transfers || suggestion?.tripData?.transfers || null
    results = results.sort((a, b) => {
      if (a?.type === 'hotel' || b?.type === 'hotel') {
        const aNum = a?.type === 'hotel' ? 1 : (a?.dir === 'outbound' ? 0 : 2);
        const bNum = b?.type === 'hotel' ? 1 : (b?.dir === 'outbound' ? 0 : 2);
        return aNum - bNum
      }

      return a.depUnix - b.depUnix
    })

    const getTransferNodeName = (node) => {
      if (!!node?.street?.name) {
        const community = node?.street?.location?.community || null
        return `${node?.street?.name || ''} ${node?.street?.number || ''}${node?.street?.letter || ''}${!!community ? ` (${community})` : ''}`
      }

      if (!!node?.street?.location?.name) {
        return node.street.location.name
      }

      return ''
    }

    const getTransferData = (transfer) => {
      const momentDep = moment(transfer?.startNode?.time?.split('+')?.[0] || ' ')
      const depUnix = momentDep.unix()
      let tmpPrice = transfer?.price || 0
      if (!!tmpPrice && typeof tmpPrice === 'object') {
        tmpPrice = tmpPrice?.includingVat || 0
      }

      return {
        type: 'transfer',
        taxiName: transfer?.product?.name,
        price: parseFloat(tmpPrice),
        momentDep,
        momentArr: moment(transfer?.endNode?.time?.split('+')?.[0] || ' '),
        depUnix,
        from: getTransferNodeName(transfer?.startNode),
        to: getTransferNodeName(transfer?.endNode),
      }
    }

    results.forEach(t => {
      const fromTKey = `from_${t.type}_${t.dir}`
      const toTKey = `to_${t.type}_${t.dir}`

      if (!!transfers?.[toTKey]) {
        const tToArray = !!transfers[toTKey].length ? transfers[toTKey] : [transfers[toTKey]]
        tToArray.forEach(tr => res.push(getTransferData(tr)))
      }

      res.push({...t})

      if (!!transfers?.[fromTKey]) {
        const tArray = !!transfers[fromTKey].length ? transfers[fromTKey] : [transfers[fromTKey]]
        tArray.forEach(tr => res.push(getTransferData(tr)))
      }
    })
    return res
  }, [trips, suggestion, getRoomCodes, i18n.language, getRoomCodes])

  const total = useMemo(() => {
    const flTotal = flightPrices.total || 0
    const railTrip = trips?.rail || suggestion?.tripData?.rail

    const oRailPrice = parseInt(railTrip?.outbound?.solution?.price?.Amount || 0)
    const rRailPrice = parseInt(railTrip?.return?.solution?.price?.Amount || 0)
    let transfersPrice = 0
    tripsData.forEach(t => {
      if (['transfer', 'hotel'].includes(t.type)) {
        transfersPrice += t.price
      }
    })
    return flTotal + oRailPrice + rRailPrice + transfersPrice
  }, [trips, suggestion, flightPrices, tripsData])

  const getConfirmBlock = useCallback(() => {
    const buttonsRow = (
      <Box key={'buttons-row'} className={classes.buttonsRow}>
        <Box key="approve-button" onClick={approve} className={classes.approveButton}>
          {t('approve suggest button')}
        </Box>
      </Box>
    )

    const totalBlock = (
      <Box key="trip-total-block" className={classes.tripTotalBlock}>
        {t('detail total')} {formatNumber(total, ' ')} sek
      </Box>
    )

    return (
      <Fragment key="bottom-general">
        {totalBlock}
        <Box key="question-block" className={classes.bookQuestion}>
          {t('approve suggest question')}
        </Box>
        {buttonsRow}
      </Fragment>
    )
  }, [total, approve])

  const buildTripDataLines = useCallback(() => {
    return tripsData.map((td, ind) => {
      const {
        type,
        roomDataText,
        provider,
        ticketTexts,
        seatTexts,
        additionalData,
        noWindowRoom,
        momentDep,
        momentArr,
        depUnix,
        from,
        to,
        city,
        hotelName,
        address,
        segments,
        railFlex,
        railClass,
        taxiName,
        underTexts,
        exceedsPolicy
      } = td
      const changes = (segments?.length || 1) - 1
      const changesText = !changes ? 'filter directly small' : (changes > 1 ? 'changes' : 'change')
      const Icon = typeIcons[type]
      let railClassText = ''

      if (!!railClass) {
        const clExplode = railClass.split('_')
        const classNumText = clExplode?.[1] === 'cs' ? t('class calm') : t('class')
        const classNum = clExplode?.[0] === '001' ? '1' : '2'
        railClassText = `${classNum} ${classNumText}`
      }

      const editBtn = (
        <Box className={classes.editBtn} onClick={() => onEdit(type)}>
          <Box display={'inline-flex'} mr={'10px'}>{t('edit btn')}</Box>
          <ArrowForwardIosIcon className={classes.editBtnArrow}/>
        </Box>
      )

      return (
        <Grid key={`trip-block-${ind}`} container className={classes.tripDataBlock}>
          <Box className={classes.tripVertLine}/>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={"auto"} className={classes.imageDataRow}>
                <Icon className={classes.tripTypeIcon}/>
              </Grid>
              <Grid item xs={"auto"} className={classes.dateRow}>
                <Box className={classes.dataRowMainText}>{momentDep.format('D')}</Box>
                <Box className={classes.dataRowMuteText}>{momentDep.format('MMM')}</Box>
              </Grid>
              <Grid item xs={true} className={classes.dataRow}>
                {type === 'hotel' ? (
                  <Box className={classes.dataRowMainText}>
                    <span key={'hotel-name'}>{hotelName} ({city})</span>
                  </Box>
                ) : (
                  <Box className={classes.dataRowMainText}>
                    <span key={'from-text'}>{from}</span>
                    <ArrowForwardIcon className={classes.dirArrow}/>
                    <span key={'to-text'}>{to}</span>
                  </Box>
                )}
                <Box  key={'under-trip-info-texts'} className={classes.dataRowMuteText}>
                  {!!provider && type === 'flight' && (
                    <Fragment>
                      <span>{provider}</span>
                      <Box className={classes.dot}/>
                    </Fragment>
                  )}
                  {(type === 'flight' || type === 'rail') && (
                    <Fragment>
                      <span>{momentDep.format('HH:mm')}</span>
                      {type !== 'flight' ? (
                        <ArrowForwardIcon className={classes.dirArrow}/>
                      ) : (
                        <span>&nbsp;&mdash;&nbsp;</span>
                      )}

                      <span>{momentArr.format('HH:mm')}</span>
                      <Box className={classes.dot}/>
                      <span>{changes > 0 ? `${changes} ` : ''}{t(changesText)}</span>
                    </Fragment>
                  )}
                  {(type === 'rail') && (
                    <Fragment>
                      <Box className={classes.dot}/>
                      <span>{railClassText}{!!railFlex ? `, ${t(railFlex)}` : '' }</span>
                    </Fragment>
                  )}
                  {type === 'transfer' && (
                    <Fragment>
                      <span>{momentDep.format('HH:mm')}</span>
                      <ArrowForwardIcon className={classes.dirArrow}/>
                      <span>{momentArr.format('HH:mm')}</span>
                      <Box className={classes.dot}/>
                      <span>{taxiName}</span>
                    </Fragment>
                  )}
                </Box>
                {!!ticketTexts?.length && (
                  <Box key={'tkt-under-Texts'} className={classes.dataRowMuteText}>
                    {
                      ticketTexts.map((txt, ind) => {
                        const res = []
                        if (ind > 0) res.push(<Box key={`tkt-type-dot-${ind}`} className={`${classes.dot}`}/>)
                        res.push(<span key={`tkt-type-${ind}`}>{txt}</span>)
                        return res
                      })
                    }
                  </Box>
                )}
                {type === 'hotel' && (
                  <Box key={'under-hotel-address'} className={classes.dataRowMuteText}>
                    <span key={`undertext-address`}>{address}</span>
                  </Box>
                )}

                {!!underTexts?.length && (
                  <Box key={'underTexts'} className={classes.dataRowMuteText}>
                    {underTexts.map((txt, ind) => {
                      const res = []
                      if (ind > 0) res.push(<Box key={`undertext-dot-${ind}`} className={classes.dot}/>)
                      res.push(<span key={`undertext-${ind}`}>{txt}</span>)
                      return res
                    })}
                  </Box>
                )}
                {!!roomDataText && (
                  <Box key={'under-hotel-roomDataText'} className={classes.dataRowMuteText}>
                    <span key={`undertext-roomDataText`}>{roomDataText}</span>
                  </Box>
                )}
                {!!additionalData?.length && (
                  <Box key={'underTexts-add'} className={`${classes.dataRowMuteText} green`}>
                    {additionalData.map((txt, ind) => {
                      const res = []
                      if (ind > 0) res.push(<Box key={`undertext-dot-${ind}`} className={`${classes.dot} green`}/>)
                      res.push(<span key={`undertext-${ind}`}>{txt}</span>)
                      return res
                    })}
                  </Box>
                )}
                {!!seatTexts?.length && (
                  seatTexts.map((st, sind) => (
                    <Box key={`p-seat-line-${sind}`} className={classes.dataRowMuteText}>
                      <span onClick={() => getSeats(type) } style={type === 'flight' ? {cursor: 'pointer'} : {}} key={`p-seat-text`}>
                        {t('seat')} {st}
                      </span>
                    </Box>
                  ))
                )}
                {!!noWindowRoom || !!exceedsPolicy && (
                  <Box key={'under-hotel-no-room'} className={classes.dataRowMuteText}>
                    {!!exceedsPolicy && (
                      <span key={`undertext-policy`} className={`${classes.infoLabel} out-of-policy`}>
                        {t('out of policy')}
                      </span>
                    )}
                    {!!noWindowRoom && (
                      <span key={`undertext-no-room`} className={`${classes.infoLabel} no-window`}>
                        {t('room without window')}
                      </span>
                    )}
                  </Box>
                )}
                {!!isMobile && editBtn}

              </Grid>
              {!isMobile && (
                <Grid item xs={"auto"} className={classes.btnRow}>
                  {editBtn}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )
    })
  }, [classes, tripsData, t, isMobile])

  const getTripsDataNew = useCallback(() => {
    const getLetters = (passenger) => {
      const fn = _.trim((passenger?.firstName || '').replace(/(Mr|Ms|Mrs)\s/i, ""))
      const ln = _.trim((passenger?.lastName || '').replace(/(Mr|Ms|Mrs)\s/i, ""))
      return ((fn?.charAt(0) || '') + (ln?.charAt(0) || '')).toUpperCase()
    }
    const users = suggestion?.passengers || []
    return (
      <Fragment>
        {!!suggestion?.tripChanged && (
          <Box key={'bannerOuter'} class={classes.bannerOuter}>
            <TripChangedBanner texts={suggestion?.changesText}/>
          </Box>
        )}
        {!state.loading && !!users.length && (
          <Box display="flex" marginBottom="18px" px={'28px'} alignItems={'center'} justifyContent={'center'}>
            <Box component="span">
              <Box className={classes.firstAvatar}>{getLetters(users[0])}</Box>
              {users.length === 2 && (
                <Box className={classes.secondAvatar}>
                  {getLetters(users[1])}
                </Box>
              )}
              {users.length > 2 && (
                <Box className={classes.secondAvatar}>+{users.length - 1}</Box>
              )}
            </Box>

            <Box
              className={classes.travelers}
              component="span"
              flex={1}
              alignItems="center"
            >
              <span>
                {users.map(u => `${u.firstName} ${u.lastName}`).join(', ')}
              </span>
            </Box>
          </Box>
        )}

        {!state.loading && (
          <Box key="trips-data" className={classes.tripsDataOuter}>
            {buildTripDataLines()}
          </Box>
        )}
        {!state.loading ? (
          <Box key="trip-bottom-block" className={classes.tripBottomBlock}>
            {getConfirmBlock()}
          </Box>
        ) : (
          <Box key="trip-loading-block" className={classes.infoBlock}>
            <CircularProgress key="loading-block-progress" className={classes.circularProgress}/>
            <Box className={classes.bookingInfoText}>{t('booking is processing')}</Box>
          </Box>
        )}
      </Fragment>
    )
  },[classes, buildTripDataLines, suggestion, state])

  const getSuccess = useCallback(() => {
    return (
      <Box key="success-block" className={classes.infoBlock}>
        <Box key="checkmark-block" className={classes.checkmarkBlock}>
          <Box className={classes.checkMark}/>
        </Box>
        <Box key="header-block" className={classes.bookingInfoText}>{t('booking completed')}</Box>
        <Box key="text-block" className={classes.bookingInfoText}>{t('suggestion approve text')}</Box>
      </Box>
    )
  },[classes, t])

  const getError = useCallback(() => {
    return (
      <Box key="success-block" className={classes.infoBlock}>
        <Box key="header-block" className={`${classes.resultHeaderBlock} error`}>{t('trip edit failed')}</Box>
      </Box>
    )
  },[classes, t])

  const selectedSeats = useMemo(() => {
    let results = []
    const flightSeats = trips?.flightSeats

    if (flightSeats) {
      const seats = flightSeats || {};
      ['outbound', 'return'].forEach(d => {
        for (let userKey in seats) {

          const p = (suggestion?.passengers || []).find(p => p.uind === userKey)
          const tmpSts = seats?.[userKey][d]?.seats || {}

          for (let stInd in tmpSts) {
            const s = tmpSts[stInd]
            results.push({
              "userId": p.uniqueId,
              "seat": `${s.n}${s.l}`,
              "price": p.price || 0,
              "flightKey": stInd,
              "seatInfo": s.seatInfo || []
            })
          }
        }
      });
    } else {
      ['outbound', 'return'].forEach(d => {
        suggestion?.passengers.forEach(p => {
          const tmpSts = p?.flightSeats?.seats?.[d] || {}
          for (let stInd in tmpSts) {
            const s = tmpSts[stInd]
            results.push({
              "userId": p.uniqueId,
              "seat": `${s.n}${s.l}`,
              "price": p.price || 0,
              "flightKey": stInd,
              "seatInfo": s.seatInfo || []
            })
          }
        });
      })
    }
    return results
  }, [trips, suggestion])

  const onSelectSeats = (seats) => {
    let userSeats = {};
    let seatsExists = false;
    const isFlightEdited = !!trips?.flight;
    const flightTrip = trips?.flight || suggestion?.tripData?.flight;
    setOpenSeats(false)
    const { outbound:outboundTrip, return:returnTrip } = flightTrip || {}
    const outgoing = isFlightEdited ? outboundTrip.original.trip : outboundTrip.segments
    const returning = isFlightEdited ? returnTrip?.original?.returnTrip || [] : returnTrip?.segments || [];

    (seats || []).forEach(s => {
      if (!s?.seat) return false

      const n = s.seat.slice(0, -1);
      const l = s.seat[s.seat.length-1];
      const price = s?.price || 0;
      const flKey = s?.flightKey;
      let dir = null;
      const oTrip = outgoing.find(t => t.uniqueInd === flKey)
      const rTrip = returning.find(t => t.uniqueInd === flKey)
      const user = suggestion.passengers.find(p => p.uniqueId === s.userId);
      const uKey = user?.uind || null;

      if (!!oTrip) dir = 'outbound';
      if (!!rTrip) dir = 'return';

      if (!n || !l || !flKey || !dir || !uKey) {
        return false;
      }

      if (!userSeats?.[uKey]) userSeats[uKey] = {};

      if (!userSeats[uKey]?.[dir]) {
        userSeats[uKey][dir] = {
          same: true,
          seats: {}
        }
      }

      userSeats[uKey][dir].seats[flKey] = {
        l,
        n,
        price,
        seatInfo: s?.seatInfo || null
      }
      seatsExists = true
    })
    selectFlightSeat(seatsExists ? userSeats : null)
    setOpenSeats(false)
  }

  return (
    <Container key="tkt-trip-data-root" maxWidth={'md'} disableGutters className={classes.root}>
      {!state.approveSent && getTripsDataNew()}
      {!state.loading && !!state.approveSent && !state.error && getSuccess()}
      {!state.loading && !!state.approveSent && !!state.error && getError()}
      {!!seatsLoading && !!openSeats && (
        <RegularLoader open={true} />
      )}

      {!!openSeats && !seatsLoading && (
        <SeatReservationModal
          modalBoolean={openSeats}
          iniSelSeats={selectedSeats || []}
          passengersList={suggestion?.passengers || []}
          seatsList={fetchedSeats}
          onSelectSeats={onSelectSeats}
          onClose={() => setOpenSeats(false)}
        />
      )}
    </Container>
  )
}

export default SelectedTripsData

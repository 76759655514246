import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react'
import {Box, CircularProgress} from '@material-ui/core'
import {useDispatch, useSelector} from 'react-redux'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import {useTranslation} from 'react-i18next'
import {
  fetchHotelRatingsAction,
  fetchHotelSearchAction,
  setHotelsError,
  removeHotelStateAll, fetchSingleHotelAction
} from "../../store/hotels/hotelsAction";
import HotelSearchContent from "../../containers/hotels/result/hotelSearchContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import HotelInfoMobile from "../../containers/hotels/singleHotel/hotelInfoMobile";
import HotelInfo from "../../containers/hotels/singleHotel/hotelInfo";
import RegularLoader from '../../components/reusable/loaders/regularLoader'
import Divider from "@material-ui/core/Divider";
import SingleHotelimages from "../../containers/hotels/singleHotel/singleHoteImages";
import Grid from "@material-ui/core/Grid";
import HotelAmenitiesCard from "../hotels/hotelAmenities";
import {AMENITIES} from "../../constants/hotelRoomCodes";
import Typography from "@material-ui/core/Typography";
import HotelRoomCard from "../hotels/hotelRoomCard";
import HotelDetails from "../../containers/hotels/singleHotel/hotelDetails";
import Paper from "@material-ui/core/Paper";
import SmallGoogleMaps from "../googleMaps/smallGoogleMaps";
import HotelMiddlestepFilter from "../../containers/hotels/filters/hotelMiddlestepFilter/hotelMiddlestepFilter";

const useStyles = makeStyles((theme) => ({
  hotelsearchOuter: {
    minHeight: 'calc(100vh - 65px)',
    width: '100%',
    display: 'flex',
    zIndex: 1
  },
  loadingBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1'
  },
  mainImg: {
    height: '320px',
    width: '100%',
    objectFit: 'cover',
  },

  mainImgPlaceholder: {
    backgroundColor: '#f3f3f3',
    height: '320px',
    width: '100%',
    objectFit: 'cover',
  },
  singleHotelOuter: {
    width: '100%',
    maxWidth: '1200px',
    backgroundColor: '#F9F9F9',
  }
}))

const AutomateBookingHotelSearch = ({ suggestion, onSelect }) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const [hotel, setHotel] = useState(null)
  const [searchParams, setSearchParams] = useState(null)
  const { singleLoading, single: fetchedHotel } = useSelector((state) => state.hotels)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const {showMap} = useSelector((state) => state.hotels)

  const buildSearchParams = useCallback(() => {
    const roomSize = suggestion.parsedSourceData?.hotelRoomSleeps || 1;
    const iniPlace = suggestion?.tripData?.hotel?.iniPlace || null;
    const selectedCheckIn = suggestion?.tripData?.hotel?.checkIn
    const selectedCheckOut = suggestion?.tripData?.hotel?.checkOut
    const selectedRoomsCount = suggestion?.tripData?.hotel?.roomsCount
    const parsedRoomsCount = suggestion.parsedSourceData?.roomsCount || suggestion?.passengers?.length || 1

    return {
      checkIn: selectedCheckIn || suggestion?.parsedSourceData?.hotelCheckIn,
      checkOut: selectedCheckOut || suggestion?.parsedSourceData?.hotelCheckOut,
      countryCode: iniPlace?.Country,
      beds: suggestion?.tripData?.hotel?.roomSleeps || roomSize,
      guests: suggestion?.passengers?.length || 1,
      roomCount: _.toString(selectedRoomsCount || parsedRoomsCount || 1),
      type: 'flight',
      lat: iniPlace?.Coordinates?.lat,
      lng: iniPlace?.Coordinates?.lng,
    }
  }, [suggestion]);

  const fetchHotels = useCallback((params) => {
    setHotel(null)
    if (
        !!params.checkIn &&
        !!params.checkOut &&
        !!params.countryCode &&
        !!params.lat &&
        !!params.lng
    ) {
      dispatch(fetchHotelRatingsAction({...params, guests: params.beds}))
      dispatch(fetchHotelSearchAction({ ...params, guests: params.beds }))
    } else {
      dispatch(removeHotelStateAll())
      dispatch(setHotelsError('no hotels'))
    }
  }, [dispatch, setHotel])

  const onSelectFilters = useCallback((filters) => {
    setSearchParams(filters)
    fetchHotels(filters)
  }, [fetchHotels, setSearchParams]);

  useEffect(() => {
    if (!searchParams) {
      const params = buildSearchParams();
      fetchHotels(params);
      setSearchParams(params)
    }
  }, [
    searchParams,
    buildSearchParams,
    fetchHotels,
    dispatch,
  ])

  const isFullWidth = !!isMobile || !showMap || !!hotel
  const innerStyles = {
    width: isFullWidth ? '100%' : '770px',
    paddingLeft: isFullWidth ? '0' : '20px',
    maxWidth: '1200px'
  }
  const singleHotelStyle = {
    padding: !isMobile ? '10px 20px' : '7px',
    borderRadius: '20px',
    marginTop: !isMobile ? '20px' : '0'
  }
  const outerStyles = {
    justifyContent: isFullWidth ? 'center' : 'flex-start',
  }
  const selectHotel = useCallback((hotelToRq) => {
    if (!hotel) {
      setHotel(hotelToRq)
      const singleSearchParams = {
        ...searchParams,
        cityCode: hotelToRq.cityCode,
        countryCode: hotelToRq.countryCode,
        hotelCode: hotelToRq.hotelCode,
        guests: searchParams.beds,
        roomCount: undefined,
        roomsCount: searchParams.roomCount,
        minAge: 0
      }
      dispatch(fetchSingleHotelAction(singleSearchParams))
    }
  }, [setHotel, searchParams])

  const selectHotelRoom = (room) => {
    const parsedHotel = fetchedHotel?.parsedHotel;
    onSelect({
      hotelData: {...parsedHotel},
      roomData: {
        ...room,
        roomsCount: parseInt(searchParams.roomCount || 1)
      },
    })
  }

  const progressBlock = (
    <Box key="loading-block" className={classes.loadingBlock}>
      <CircularProgress/>
    </Box>
  )
  const renderSingleHotel = () => {
    if (!!singleLoading) {
      return progressBlock
    }

    const parsedHotel = fetchedHotel?.parsedHotel
    const amenities = parsedHotel?.amenities || []
    const hotelImages = parsedHotel?.additional_images || []

    const { freeWifi, breakfastIncluded, cancellationType, image:mainImage } = parsedHotel || {}
    const LONGITUDE = parsedHotel?.CachedData?.LONGITUDE
    const LATITUDE = parsedHotel?.CachedData?.LATITUDE
    const stars = parsedHotel?.CachedData?.LOCALRATING
        ? parseInt(parsedHotel?.CachedData?.LOCALRATING)
        : parsedHotel?.CachedData?.SELFRATING

    const translatedAmenities = AMENITIES()
    const uniqueAmenityLabels = new Set()
    const renderHotelAmenitie = amenities
      .map((amenity) => ({
        code: amenity,
        label: translatedAmenities[amenity],
      }))
      .filter((amenityObj) => translatedAmenities[amenityObj.code])
      .filter((amenityObj) => {
        if (uniqueAmenityLabels.has(amenityObj.label)) {
          return false
        }
        uniqueAmenityLabels.add(amenityObj.label)
        return true
      })
      .map((amenityObj) => (
        <Grid item key={amenityObj.code}>
          <HotelAmenitiesCard
            RoomAmenityCode={amenityObj.code}
            RoomAmenityLabel={amenityObj.label}
            hotel={true}
          />
        </Grid>
      ))

    const renderHotelRooms = fetchedHotel?.rooms.map((room, key) => (
      <Box mt={key === 0 ? 0 : 2} key={key}>
        <HotelRoomCard
          room={room}
          hotel={fetchedHotel}
          searchParams={{
            ...searchParams,
            roomCount: undefined,
            roomsCount: searchParams.roomCount
          }}
          onSelect={selectHotelRoom}
        />
      </Box>
    ))

    const tmpGuests = suggestion?.passengers?.length || 1

    return (
      <Box className={classes.singleHotelOuter} style={singleHotelStyle} p={1}>
        {!!isMobile && (
          <Box display="flex">
            {mainImage?.split('.')[mainImage?.split('.').length - 1] !==
            'png' ? (
              <img src={mainImage} alt="placeholder img" className={classes.mainImg}/>
            ) : (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                className={classes.mainImgPlaceholder}
              >
                <Typography>Image missing</Typography>
              </Box>
            )}
          </Box>
        )}
        {isMobile ? (
          <HotelInfoMobile
            stars={stars}
            info={parsedHotel}
            room={fetchedHotel?.rooms[0]}
            addToCheckout={() => selectHotelRoom(fetchedHotel?.rooms?.[0] || null)}
            searchParams={{
              ...searchParams,
              roomCount: undefined,
              roomsCount: searchParams.roomCount
            }}
          />
        ) : (
          <HotelInfo
            stars={stars}
            info={parsedHotel}
            room={fetchedHotel?.rooms[0]}
            addToCheckout={() => selectHotelRoom(fetchedHotel?.rooms?.[0] || null)}
            searchParams={{
              ...searchParams,
              roomCount: undefined,
              roomsCount: searchParams.roomCount
            }}
          />
        )}
        {!isMobile && (
          <Fragment>
            <Divider />
            <Box mb={4} mt={4}>
              <SingleHotelimages hotelImages={hotelImages} />
            </Box>
          </Fragment>
        )}
        {!isMobile && (
          <Fragment>
            <Divider />
            <Box mt={2} mb={2}>
              <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
              >
                <Grid item>
                  <Typography
                      className={classes.weight}
                      variant="h5"
                  >
                    {t('select the room')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {tmpGuests}{' '}
                    {tmpGuests > 1 ? t('rooms') : t('room')},{' '}
                    {tmpGuests}{' '}
                    {tmpGuests > 1 ? t('travelers') : t('one travel')}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Fragment>
        )}

        <Box mt={2} mb={2}>
          <Box mt={2}>
            <Grid
                container
                direction={isMobile ? 'column' : 'row'}
                spacing={2}
            >
              <Grid item xs={12} md={8} sm={12}>
                <Box>{renderHotelRooms}</Box>

                {fetchedHotel && (
                  <Paper elevation={0} className={classes.card}>
                    <Box mt={2} p={2} pb={3} pt={3} mb={isMobile ? 10 : 0}>
                      <Box mb={2}>
                        <Typography
                          className={classes.weight}
                          variant="h5"
                          gutterBottom
                        >
                          {t('amenities')}
                        </Typography>
                      </Box>
                      {renderHotelAmenitie}
                    </Box>
                  </Paper>
                )}
              </Grid>
              {!isMobile && (
                <Grid item xs={12} sm={4}>
                  <Paper elevation={0} className={classes.card}>
                    <Box p={2} mb={10}>
                      <SmallGoogleMaps
                          lat={LATITUDE}
                          lng={LONGITUDE}
                      />
                    </Box>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box key="tkt-hotel-edit-outer" style={outerStyles} className={classes.hotelsearchOuter}>
      {!searchParams ? progressBlock : (
        !hotel ? (
          <Box key="tkt-hotel-edit-inner" style={innerStyles}>
            <HotelSearchContent
                lat={searchParams?.lat}
                lng={searchParams?.lng}
                isMiddleStep={true}
                searchFilters={searchParams}
                onSelect={selectHotel}
                onSelectFilter={onSelectFilters}
                mapFieldPosition={{top: 0, right: 0}}
            />
          </Box>
        ) : (
          renderSingleHotel()
        )
      )}
    </Box>
  )
}

export default AutomateBookingHotelSearch

import agent from './agent'

// **POST** approve suggestion by token
export const approveSuggestion = async (token, ctKey = null) => {
  try {
    const url = '/v2/automate-booking/user-approve'
    const { data } = await agent.post(url, {token}, {cityCityCT: ctKey})
    return data
  } catch (error) {
    throw error
  }
}

// **POST** fetch suggestion by token
export const fetchSuggestion = async (token, ctKey = null) => {
  try {
    const url = '/v2/automate-booking/get-suggestion-by-token'
    const { data } = await agent.post(url, {token}, {cityCityCT: ctKey})
    return data
  } catch (error) {
    throw error
  }
}

// **POST** fetch rail schedules by token
export const fetchTripsByToken = async (token, rqData, ctKey = null) => {
  try {
    const url = '/v2/automate-booking/get-schedules-by-token'
    const { data } = await agent.post(url, {...rqData, token}, {cityCityCT: ctKey})
    return data
  } catch (error) {
    throw error
  }
}

export const getBags = async (rqData, ctKey = null) => {
  const url = `/v2/automate-booking/get-bags`

  const { data } = await agent.post(url, rqData)
  return data?.bags || null
}

export const getFFList = async (rqData, ctKey = null) => {
  try {
    const url = `/v2/automate-booking/get-flights-fares`
    const { data } = await agent.post(url, rqData)
    return data
  } catch (e) {
    return null
  }
}

// **POST** edit and approve
export const editAndApproveTrips = async (token, rqData, ctKey = null) => {
  try {
    const url = '/v2/automate-booking/edit-approve-trips-by-token'
    const { data } = await agent.post(url, {...rqData, token}, {cityCityCT: ctKey})
    return data
  } catch (error) {
    throw error
  }
}

// **POST** check suggestion get fresh
export const checkSuggestion = async (token, ctKey = null) => {
  try {
    const url = '/v2/automate-booking/get-fresh-selection'
    const { data } = await agent.post(url, {token}, {cityCityCT: ctKey})
    return data
  } catch (error) {
    throw error
  }
}
import React, {Fragment} from 'react'
import {Box, Grid} from '@material-ui/core'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FilterButton from './FilterButton.js'

const useStyles = makeStyles((theme) => ({
  dFlex: {
    display: 'flex'
  },
  centerdFlex: {
    display: 'flex',
    justifyContent: 'center'
  }
}))

const FlightTripSearchItem = ({
  filters,
  possibleFilters,
  onSelect,
  tripType = 'rail'
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const isBigMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  const selectFilter = (filterName, val) => {
    let newValue = {...(filters || {}), [filterName]: val}
    onSelect(newValue)
  }

  const dynamicStyles = {}
  if (isBigMobile) dynamicStyles.padding = '0 16px';
  return (
    <Grid key="filters-line-outer" style={dynamicStyles} container spacing={0}>
      <Grid
        key="lefy-filters-block"
        xs={12}
        sm={true}
        style={{flexDirection: isMobile ? 'column' : 'row' }}
        className={classes.dFlex}
      >
        <Box
          key="filter-stops-block"
          width={isMobile ? '100%' : 'auto'}
          mb={isMobile ? '10px' : '0'}
          mr={isMobile ? '0' : '10px'}
          display={'flex'}
          justifyContent={'center'}
        >
          <FilterButton
            isMulti={true}
            fullWidth={isMobile}
            list={possibleFilters.stops}
            selected={filters.stops || null}
            nullText={t('stops', {count: null})}
            onSelect={(val) => selectFilter('stops', val)}
          />
        </Box>
        <Box
          key="filter-airlines-block"
          width={isMobile ? '100%' : 'auto'}
          mb={isMobile ? '10px' : '0'}
          mr={isMobile ? '0' : '10px'}
          display={'flex'}
          justifyContent={'center'}
        >
          <FilterButton
            isMulti="true"
            fullWidth={isMobile}
            list={possibleFilters.airlines}
            selected={filters.airlines || null}
            nullText={t(tripType === 'rail' ? 'no companies' :'no airlines')}
            allText={t(tripType === 'rail' ? 'all companies' :'all airlines')}
            multiLocaleKey={tripType === 'rail' ? 'companies' :'airlines'}
            onSelect={(val) => selectFilter('airlines', val)}
          />
        </Box>
      </Grid>
      <Grid key="right-filters-block" xs={12} sm={'auto'} className={classes.centerdFlex}>
        <FilterButton
          isMulti={false}
          fullWidth={isMobile}
          list={possibleFilters.orderBy}
          selected={filters.orderBy || null}
          onSelect={(val) => selectFilter('orderBy', val) }
        />
      </Grid>
    </Grid>
  )
}

export default FlightTripSearchItem

import React, {Fragment, useRef, useCallback, useState} from 'react'
import { useFormikContext } from 'formik'
import {
  Box,
  Typography,
  useTheme,
  Divider
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import AutoCompleteInput from "../../../components/reusable/autoCompleteInput";
import { fetchApprovers } from "../../../repositories/users";
import {useTranslation} from "react-i18next";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '32px 32px 72px 32px'
  },
  approverInput: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      cursor: 'pointer',
      '& .MuiAutocomplete-input': {
        cursor: 'pointer',
        borderRadius: '6px',
        height: '19px',
        fontWeight: 'normal',
        padding: '17.5px 14px',
      },
      borderRadius: '6px',
      border: `1px solid ${theme.palette.border}`,
      '&.Mui-focused': {
        borderColor: `${theme.palette.primary.main}`
      }
    }
  },
  errorText: {
    color: `${theme.palette.secondary.main}`,
    margin: '3px 14px 0 14px',
    fontSize: '12px'
  },
  arrowDownIcon: {
    color: `${theme.palette.primary.main}`
  },
  divider: {
    marginBottom: '16px'
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  inputBox: {
    '&.error $approverInput .MuiAutocomplete-inputRoot, error $approverInput .MuiAutocomplete-inputRoot.Mui-focused': {
      borderColor: `${theme.palette.secondary.main}`
    }
  },
  medium: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '14px',
    marginBottom: theme.spacing(1),
    marginTop: '16px',
  },
}))

/**
 * component for choosing user who will approve booking
 *
 * @returns {JSX.Element}
 */
const chooseApprover = ({}) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles({ isMobile })
  const [approvers, setApprovers] = useState([])
  const { t } = useTranslation()
  const formik = useFormikContext()

  const approver = formik.values?.approver;
  const touched = formik.touched?.approver;
  const error = formik.errors?.approver;
  const inpRef = useRef()

  const optionLabel = (option) => {
    return `${option['firstName']} ${option['lastName']} (${option.email})`
  }

  const renderOption = (option, { inputValue }) => {
    const matches = match(
      `${option['firstName']} ${option['lastName']} (${option.email})`,
      inputValue
    )
    const parts = parse(
      `${option['firstName']} ${option['lastName']} (${option.email})`,
      matches
    )

    return (
      <Fragment>
        <Typography>
          {parts.map((part, index) => (
            <span
              key={index}
              style={{ fontWeight: part.highlight ? 700 : 400 }}
            >
              {part.text}
            </span>
          ))}
        </Typography>
      </Fragment>
    )
  }

  const getApprovers = useCallback(async (value) => {
    let list = []
      try {
        list = await fetchApprovers({name: value}, 'fetch-approvers')
        setApprovers(list)
      } catch (e) {}
  }, []);
  const customFilterFunc = (option) => {
    return `${option['firstName']} ${option['lastName']} (${option.email})`
  }

  return (
    <Box className={classes.root}>
      <Box key="header" mb={2} mt={0}>
        <Typography variant="h5" className={classes.bold}>{t('approval')}</Typography>
      </Box>
      <Divider className={classes.divider} />

      <Typography key={'above-field-text'} className={classes.medium}>
        {t('who shell approve your booking')}*
      </Typography>

      <Box key={'input-box'} className={`${classes.inputBox} ${touched && error ? 'error' : ''}`}>
        <AutoCompleteInput
          customFilterFunc={customFilterFunc}
          renderOption={renderOption}
          fetch={getApprovers}
          value={approver}
          forwardedRef={inpRef}
          endAdornment={(
            <KeyboardArrowDownIcon key={'arrow-down'} className={classes.arrowDownIcon}/>
          )}
          onChange={(_, value) => {
            formik.setFieldValue('approver', value)
          }}
          array={approvers}
          optionLabel={optionLabel}
          label={t('select approver')}
          placeholder={t('select approver')}
          fullWidth={true}
          inputClass={classes.approverInput}
        />
        {!!touched && !!error && (
          <Box key={'error-text'} className={classes.errorText}>
            {error}
          </Box>
        )}

      </Box>
    </Box>
  )
}

export default chooseApprover

import CardContent from '@material-ui/core/CardContent'
import moment from 'moment'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  useTheme,
} from '@material-ui/core'
import MapIcon from '@material-ui/icons/Map'
import EditIcon from '@material-ui/icons/Edit'
import ListIcon from '@material-ui/icons/List'
import CancelIcon from '@material-ui/icons/Cancel'
import SearchIcon from '@material-ui/icons/Search'
import Typography from '@material-ui/core/Typography'
import SortBy from '../../../components/reusable/sortBy'
import SearchResultsContainer from './searchResultContainer'
import Card from '@material-ui/core/Card/Card'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import useSort from '../../../hooks/useSortHook'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import TuneRoundedIcon from '@material-ui/icons/TuneRounded'
import { DrawerContext } from './hotelSearchContent'
import { MiddlestepDrawerContext } from '../../../pages/middlestep/middlestep'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchHotelSearchAction,
  setEnableZoneFilter,
  setMapCenter,
  setSelectedHotel,
  setZoneFilteredSearch,
} from '../../../store/hotels/hotelsAction'
import isIframed from '../../../utils/isIframed'
import { getHotelSortTypes } from '../../../utils/hotelSortTypes'
import HotelZoneFilter from '../../../components/hotels/hotelZoneFilter'
import useHotelAvropaFilterHook from '../../../hooks/useHotelAvropaFilterHook'
import { Check } from '@material-ui/icons'
import { fetchCityBasedOnCoordinates } from '../../../repositories/hotel'
import { getVirtualCustomerConfig } from '../../../utils/virtualCustomerConfigs'
import SearchLocationToggle from '../../../components/hotels/searchLocationToggle'
import { fetchCityByIata } from '../../../repositories/flights'
import HotelBackgroundMap from '../../../components/hotels/hotelBackgroundMap'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const useStyles = makeStyles((theme) => ({
  align: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    minHeight: '100%',
  },
  title: {
    paddingTop: '12px !important',
    paddingBottom: '12px !important',
  },
  fullHeight: { minHeight: '100%' },
  forceRight: {
    marginLeft: 'auto',
  },
  btns: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(3),
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },

  card: {
    background: '#f7f7f7',
    borderRadius: '12px',
    padding: theme.spacing(1),
    marginTop: '32px',
  },
  cardMobile: {
    background: '#f7f7f7',
    borderRadius: '12px',
    padding: theme.spacing(0),
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },

  text: {
    whiteSpace: 'nowrap',
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: '1.1px',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '14px',
  },

  input: {
    height: '100%',
    width: '100%',
    borderRadius: '5px',
    border: '1px solid #08c5b9',
    backgroundColor: 'white',
    paddingLeft: '10px',
    paddingRight: '10px',
    margin: '0',
    fontWeight: 600,
    '&::placeholder': {
      color: 'red !important',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '10px',
    },
  },

  zoneFilter: {
    marginLeft: 'auto',
    backgroundColor: '#e7f2ed',
    padding: '6px',
    borderRadius: '8px',
  },

  changeTripContainer: {
    marginTop: '12px',
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(3),
    fontSize: '14px',
    padding: '8px 16px 8px 16px',
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },

  changeTripText: {
    fontSize: '14px',
  },

  changeTripButton: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
    padding: '0px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },

  middleFilter: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(3),
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    paddingRight: theme.spacing(1.5),
    fontSize: '14px',
    [theme.breakpoints.down(1200)]: {
      maxWidth: '35%',
    },
    [theme.breakpoints.down(950)]: {
      maxWidth: 'none',
    },
  },

  loadingBar: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: theme.typography.fontWeightMedium,
    padding: '10px',

    backgroundColor: '#e7f2ed',
    borderRadius: '8px',
    marginBottom: theme.spacing(2),
  },
}))
import {DrawerContext as HotelSearchContext} from '../../hotels/result/hotelSearchContent';
import NoTripsFound from "../../../components/general/noTripsFound";

const HotelSearchResults = ({
  sortContext = null,
  isMobile,
  isMiddleStep,
  search,
  toggleMap,
  showMap,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const params = useParams()
  const { searchFilters, toggleSearchDrawer } = useContext(HotelSearchContext)
  const { countryCode, lat, lng, checkIn, checkOut, roomCount, guests } = searchFilters || params
  const { enableZoneFilter, city, isSearchLoading } = useSelector(
    (state) => state.hotels
  )
  const { priorityHotels } = getVirtualCustomerConfig()
  const [isShowingAirport, setIsShowingAirport] = useState(false)
  const { items: checkoutItems } = useSelector((state) => state.checkout)
  const [alternateLocation, setAlternateLocation] = useState(null)
  const location = useLocation()

  const alternateLocationIata = useMemo(() => {
    if (!isMiddleStep) return null
    if (location.pathname.includes('/trains')) return null
    const flight = checkoutItems.find((item) => item.type === 'Flight')
    const iata = flight?.outgoing?.to?.shortName
    return iata
  }, [isMiddleStep, checkoutItems])

  useEffect(() => {
    const getLocationByIata = async () => {
      let location = await fetchCityByIata(alternateLocationIata)
      setAlternateLocation(location)
    }
    getLocationByIata().catch((e) => {
      console.log(e)
    })
  }, [alternateLocationIata])

  const sortTypes = getHotelSortTypes(priorityHotels ? 'agency' : null)
  const localUseSort = useSort(
    sortTypes,
    search,
    null,
    'hotel',
    priorityHotels ? 'agency' : null
  )
  const {
    sort,
    anchorEl,
    handleClose,
    handleClick,
    nameSearchStr,
    handleNameSearch,
  } = sortContext || localUseSort

  const { toggleDrawer, filteredSearch, activeFilters } =
    useContext(DrawerContext)
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up(600))
  const [showSearchField, setShowSearchfield] = useState(false)
  const dispatch = useDispatch()
  const { filtered: zoneFiltered, handleChange: handleZoneFilter } =
    useHotelAvropaFilterHook(search)
  const { useAvropa } = useSelector((state) => state.auth)
  const { searchChecksum } = useSelector((state) => state.hotels)
  const [cityName, setCityName] = useState(city?.Location)

  const isSweden = searchChecksum?.startsWith('SE')

  useEffect(() => {
    const getCity = async (lat, lng) => {
      const city = await fetchCityBasedOnCoordinates({
        lat,
        lng,
      })
      return city['Location']
    }

    getCity(lat, lng).then((result) => {
      setCityName(result)
    })
  }, [lat, lng])

  let resultsWithoutSearch =
    isSweden && enableZoneFilter && zoneFiltered ? zoneFiltered : filteredSearch

  let results =
    showSearchField && nameSearchStr
      ? resultsWithoutSearch.filter((h) =>
          h?.title.match(new RegExp(nameSearchStr, 'i'))
        )
      : resultsWithoutSearch

  const zoneSortType = {
    type: 'avropa',
    label: 'Avropa',
  }

  const toggleHotelNameSearch = () => {
    if (!showSearchField) dispatch(setSelectedHotel(null))
    setShowSearchfield(!showSearchField)
  }

  const toggleZoneFilter = () => {
    dispatch(setEnableZoneFilter(!enableZoneFilter))
  }

  useEffect(() => {
    dispatch(setZoneFilteredSearch(zoneFiltered))
  }, [zoneFiltered])

  const handleChangeLocation = (showAlternate) => {
    const changeLocation = (code, lat, lng) => {
      const data = {
        countryCode: code,
        lat: lat.toFixed(7),
        lng: lng.toFixed(7),
        checkIn,
        checkOut,
        guests,
        roomCount: '1',
      }
      let point = { lat: lat, lng: lng, requiresOffset: true }
      dispatch(setMapCenter(point))

      dispatch(fetchHotelSearchAction(data))
      // dispatch(
      //   fetchHotelRatingsAction({
      //     lat: lat,
      //     lng: lng,
      //     countryCode: aita['Country Code'],
      //   })
      // )
    }

    if (showAlternate) {
      const { 'Country Code': country, lat, lng } = alternateLocation

      changeLocation(country, lat, lng)
      setIsShowingAirport(true)
    } else {
      changeLocation(countryCode, Number(lat), Number(lng))

      setIsShowingAirport(false)
    }
  }

  return (
    <Card
      className={isMobile ? classes.cardMobile : classes.card}
      elevation={0}
    >
      <CardContent className={classes.cardContent}>
        <Grid container direction="column" spacing={isMobile ? 0 : 1}>
          {isMiddleStep && (
            <Grid
              item
              direction="row"
              style={{ flex: 1 }}
              container
              xs
              mt={2}
              className={classes.title}
            >
              <Typography className={classes.weight} variant="h4">
                {t('title add hotel')}
              </Typography>
            </Grid>
          )}

          <Grid style={{ flex: 1 }} item direction="row" container xs>
            <Box
              display="flex"
              width="100%"
              style={{ flexWrap: 'wrap', rowGap: '8px' }}
            >
              {isSweden && useAvropa && (
                <Box mr={1}>
                  <SearchLocationToggle
                    label1={'Avropa.se'}
                    label2={
                      t('all') +
                      ' ' +
                      (search.length > 0 ? search.length : '') +
                      ' ' +
                      t('hotels')
                    }
                    onChange={toggleZoneFilter}
                    isToggled={!enableZoneFilter}
                  />
                </Box>
              )}

              {(!isSweden || !enableZoneFilter) && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={!isMobile ? 'none' : 'flex-start'}
                  mr={1}
                >
                  <Button
                    onClick={toggleDrawer('left', true)}
                    className={classes.btns}
                    startIcon={
                      (isMobile || !showMap) && (
                        <Box display="flex" alignItems="center">
                          <TuneRoundedIcon color="primary" />
                          <Typography
                            color="primary"
                            style={{ fontWeight: 700 }}
                          >
                            {activeFilters > 0 && activeFilters}
                          </Typography>
                        </Box>
                      )
                    }
                  >
                    {t('filter')}
                  </Button>
                </Box>
              )}

              <Box
                display="flex"
                alignItems="center"
                justifyContent={!isMobile ? 'none' : 'flex-start'}
                mr={1}
              >
                <Button
                  disableRipple={true}
                  disableTouchRipple={true}
                  onClick={() => toggleHotelNameSearch()}
                  style={{ textTransform: 'none' }}
                  className={classes.btns}
                  endIcon={
                    <Box display="flex" alignItems="center">
                      <SearchIcon color="primary" />
                    </Box>
                  }
                >
                  {t('hotel')}
                </Button>
              </Box>

              {(!isSweden || !enableZoneFilter) && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={!isMobile ? 'none' : 'flex-start'}
                  mr={1}
                >
                  <SortBy
                    sort={sort}
                    sortTypes={sortTypes}
                    anchorEl={anchorEl}
                    handleClick={handleClick}
                    handleClose={handleClose}
                  />
                </Box>
              )}

              {isMobile ||
                (!isIframed && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={!isMobile ? 'none' : 'flex-start'}
                    style={{ marginLeft: 'auto' }}
                  >
                    <Button
                      onClick={toggleMap}
                      className={classes.btns}
                      style={{ marginLeft: 'auto' }}
                      startIcon={
                        showMap ? (
                          <ListIcon color="primary" />
                        ) : (
                          <MapIcon color="primary"></MapIcon>
                        )
                      }
                    >
                      {showMap ? t('hide map') : t('show map')}
                    </Button>
                  </Box>
                ))}
            </Box>
          </Grid>

          {isDesktop && isMiddleStep && (
            <Grid
              className={classes.changeTripContainer}
              direction="row"
              container
            >
              <Box className={classes.changeTripText}>
                {cityName ? cityName + ', ' : ''}
                {moment(checkIn).format('D MMM')}–
                {moment(checkOut).format('D MMM')}
                ,&nbsp;
                {roomCount}
                &nbsp;
                {roomCount === '1' ? t('room') : t('rooms')}
                ,&nbsp;
                {guests}
                &nbsp;
                {guests > 1
                  ? t('traveller_plural').toLowerCase()
                  : t('traveller').toLowerCase()}
              </Box>

              {typeof(toggleSearchDrawer) === 'function' && (
                <Box
                    className={classes.changeTripButton}
                    onClick={toggleSearchDrawer}
                >
                  {t('change hotel search')}
                  <EditIcon color="primary" style={{ height: '18px' }}></EditIcon>
                </Box>
              )}
            </Grid>
          )}

          {showSearchField && (
            <Grid item xs={12}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent={!isMobile ? 'none' : 'flex-start'}
                style={{ marginLeft: 'auto' }}
              >
                <TextField
                  value={nameSearchStr || ''}
                  onChange={(e) => handleNameSearch(e.target.value)}
                  className={classes.input}
                  placeholder={t('search hotels')}
                  autoComplete={'off'}
                  inputProps={{ autoCorrect: 'off', spellCheck: 'false' }}
                  InputProps={{
                    autoFocus: true,
                    disableUnderline: true,
                    endAdornment: (
                      <IconButton onClick={() => handleNameSearch('')}>
                        <CancelIcon style={{ color: '#08c5b9' }}></CancelIcon>
                      </IconButton>
                    ),
                    classes: {
                      adornedEnd: classes.adornedEnd,
                    },
                  }}
                />
              </Box>
            </Grid>
          )}

          <Grid
            item
            xs={12}
            style={{ display: isSweden && enableZoneFilter ? 'block' : 'none' }}
          >
            <Box className={classes.zoneFilter} mt={1}>
              <HotelZoneFilter onChange={handleZoneFilter} />
            </Box>
          </Grid>

          {isMiddleStep && alternateLocation && (
            <Box mt={2}>
              <SearchLocationToggle
                isToggled={isShowingAirport}
                onChange={() => handleChangeLocation(!isShowingAirport)}
                label1={t('hotels in', { city: alternateLocation['City SWE'] })}
                label2={t('hotels at', {
                  location: alternateLocation['Airport SWE'],
                })}
              />
            </Box>
          )}

          <Grid item xs={12}>
            {isSearchLoading && (!enableZoneFilter || !isSweden) && (
              <Box className={classes.loadingBar} mt={isMobile ? 2 : 1}>
                <CircularProgress style={{ marginRight: '10px' }} size={20} />{' '}
                {t('hotels loading')}
              </Box>
            )}

            <Box marginX={0} mt={isMobile ? 2 : 1}>
              {results.length > 0 || isSearchLoading ? (
                <SearchResultsContainer
                    sort={enableZoneFilter ? zoneSortType : sort}
                    isMiddleStep={isMiddleStep}
                    search={results}
                    showZoneData={enableZoneFilter}
                />
              ) : (
                <NoTripsFound isHotel={true}/>
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default HotelSearchResults
